import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import axios from 'axios';
import { OutlinedInput, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
// import clsx from 'clsx';
import Botao from '../../../components/Botao/Botao';
import DetalhesImobiliaria from '../DetalhesImobiliaria';

import {
  Content,
  DetalhesContainer,
  DetalhesContent,
  DetalhesHeader,
  Imobiliaria,
  BotoesContainer,
  InputsContainer,
  InputsContent,
  TrocarFoto,
} from './styles';
import InputField from '../../../components/Inputs/InputField';
import correioAPI from '../../../services/CorreioAPI';
import { mascaraCNPJ, mascaraTelefone } from '../../../utils/mascaras';
import SelectField from '../../../components/SelectField/SelectField';
import ImobiliariasService from '../../../services/ImobiliariasService';
import EmpreendimentosService from '../../../services/EmpreendimentosService';
import Snackbars from '../../../components/Snackbars';
import DeleteModal from '../../../components/DeleteModal';
import sleep from '../../../utils/sleep';

const EditarImobiliaria = (agencyData: any) => {
  const [id, setid] = useState('');
  const [enterprises, setenterprises] = useState<any>();
  const [enterprisesNames, setEnterprisesNames] = useState<string[]>();

  const [allEnterprises, setAllEnterprises] = useState<any[]>([{}]);
  const [allEnterprisesNames, setAllEnterprisesName] = useState<string[]>();

  const [agencyName, setAgencyName] = useState('');
  const [agencyTelefone, setAgencyTelefone] = useState('');
  const [agencyRazaoSocial, setAgencyRazaoSocial] = useState('');
  const [agencyEmail, setAgencyEmail] = useState('');
  const [agencyCidade, setAgencyCidade] = useState('');
  const [agencyLogradouro, setAgencyLogradouro] = useState('');
  const [agencyUF, setAgencyUF] = useState({ title: '', value: '' });
  const [agencyCEP, setAgencyCEP] = useState('');
  const [agencyCnpj, setCNPJ] = useState('');
  const [agencyBairro, setAgencyBairro] = useState('');
  const [agencyNumero, setAgencyNumero] = useState('');
  const [agencyObservacao, setAgencyObservacao] = useState('');
  const [creciJ, setCreciJ] = useState('');
  const [creciF, setCreciF] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [snackbarProps, setSnackbarProps] = useState({
    message: '',
    type: 'success',
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  // const { id, nome, telefone, email, cnpj, razao_social, endereco, cep, logradouro, uf, cidade, enterprises } = agencyData.agencyData;
  const location = useLocation<any>();
  const history = useHistory();

  useEffect(() => {
    const {
      id,
      nome,
      telefone,
      email,
      cnpj,
      razao_social,
      endereco,
      cep,
      logradouro,
      uf,
      cidade,
      enterprises,
      creciF,
      creciJ,
      observacao,
    } = location.state;
    console.log('****************', location.state)
    setid(id);
    setAgencyName(nome);
    setAgencyTelefone(telefone);
    setAgencyEmail(email);
    setCNPJ(cnpj);
    setAgencyRazaoSocial(razao_social);
    setAgencyCEP(cep);
    setAgencyLogradouro(logradouro);
    setAgencyUF({ title: uf, value: uf });
    setAgencyCidade(cidade);
    setAgencyObservacao(observacao)
    setenterprises(enterprises);
    setCreciJ(creciJ)
    setCreciF(creciF)
    setEnterprisesNames(
      enterprises.map((enterprise: any) => {
        return enterprise.name;
      }),
    );
  }, [location]);

  function voltarTela() {
    history.goBack();
  }

  const handleSnackbarClose = (
    event?: React.SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const onSubmit = async () => {
    setIsLoading(true);
    setSnackbarProps({
      message: 'Carregando...',
      type: 'info',
    });
    setSnackbarOpen(true);
    try {
      await ImobiliariasService.updateImobiliaria({
        id,
        telefone: agencyTelefone,
        email: agencyEmail,
        cnpj: agencyCnpj,
        razao_social: agencyRazaoSocial,
        cep: agencyCEP,
        logradouro: agencyLogradouro,
        uf: agencyUF.value,
        cidade: agencyCidade,
        nome: agencyName,
        numero: agencyNumero,
        bairro: agencyBairro,
        observacao: agencyObservacao,
        creci_f: creciF,
        creci_j: creciJ,
      });
      if (enterprisesNames) {
        const enterprises_id = allEnterprises
          .filter(
            (enterprise: any) => enterprisesNames.indexOf(enterprise.name) > -1,
          )
          .map((enterprise: any) => enterprise.id);

        await ImobiliariasService.updateImobiliariaEmpreendimento({
          agency_id: id,
          enterprises_id,
        });
      }
      setSnackbarProps({
        message: 'As alterações foram salvas com sucesso!',
        type: 'success',
      });
    } catch (error) {
      console.error(error);
      setSnackbarProps({
        message: 'Não foi possível fazer as alterações!',
        type: 'error',
      });
    }
    setIsLoading(false);
    await sleep(500);
    setSnackbarOpen(false);
    history.push('/painel/imobiliarias');
  };

  const deletarImobiliaria = async () => {
    setIsLoading(true);
    setSnackbarProps({
      message: 'Carregando...',
      type: 'info',
    });
    setSnackbarOpen(true);
    try {
      await ImobiliariasService.deleteImobiliaria(id);
      setSnackbarProps({
        message: 'Produto deletado com sucesso!',
        type: 'success',
      });
    } catch {
      console.error('erro ao deletar imobiliaria');
      setSnackbarProps({
        message: 'Ocorreu um erro ao deletar o produto!',
        type: 'error',
      });
    }
    setIsLoading(false);
    await sleep(500);
    setSnackbarOpen(false);
    history.push('/painel/imobiliarias');
  };

  const baseURL = 'https://viacep.com.br/ws/';

  const getEndereco = async (cep: string) => {
    try {
      await axios.get(`${baseURL}${cep}/json/`).then(res => {
        const endereco = res.data;
        setAgencyLogradouro(endereco.logradouro);
        setAgencyCidade(endereco.localidade);
        setAgencyUF({ title: endereco.uf, value: endereco.uf });
      });
    } catch (error) {
      console.log('erro');
    }
  };

  async function autoComplete(e: any) {
    setAgencyCEP(e.target.value.toString());
    if (e.target.value.length >= 8) {
      await getEndereco(e.target.value.toString());
    }
  }

  function setUFValue(e: any) {
    setAgencyUF(e.target.outerText.toLowerCase());
  }

  function setCNPJValue(e: any) {
    const maskedCNPJ = mascaraCNPJ(e.target.value);

    setCNPJ(maskedCNPJ);
  }

  const ufOptions = [
    { title: 'RO', value: 'RO' },
    { title: 'AC', value: 'AC' },
    { title: 'AM', value: 'AM' },
    { title: 'RR', value: 'RR' },
    { title: 'PA', value: 'PA' },
    { title: 'AP', value: 'AP' },
    { title: 'TO', value: 'TO' },
    { title: 'MA', value: 'MA' },
    { title: 'PI', value: 'PI' },
    { title: 'CE', value: 'CE' },
    { title: 'RN', value: 'RN' },
    { title: 'PB', value: 'PB' },
    { title: 'PE', value: 'PE' },
    { title: 'AL', value: 'AL' },
    { title: 'SE', value: 'SE' },
    { title: 'BA', value: 'BA' },
    { title: 'MG', value: 'MG' },
    { title: 'ES', value: 'ES' },
    { title: 'RJ', value: 'RJ' },
    { title: 'SP', value: 'SP' },
    { title: 'PR', value: 'PR' },
    { title: 'SC', value: 'SC' },
    { title: 'RS', value: 'RS' },
    { title: 'MS', value: 'MS' },
    { title: 'MT', value: 'MT' },
    { title: 'GO', value: 'GO' },
    { title: 'DF', value: 'DF' },
  ];

  const fetchEnterprises = useCallback(async () => {
    await EmpreendimentosService.listAllEmpreendimentos().then(response => {
      setAllEnterprises(response.enterprise);
      setAllEnterprisesName(
        response.enterprise.map((enterpriseName: any) => {
          return enterpriseName.name;
        }),
      );
    });
  }, []);

  useEffect(() => {
    fetchEnterprises();
  }, []);

  const [personName, setPersonName] = React.useState<string[]>([]);

  const handleChange = (event: any) => {
    setEnterprisesNames(event.target.value);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <>
      <Content>
        <DetalhesContainer>
          <DetalhesHeader>
            <Imobiliaria>
              <h1>
                <span>Imobiliária</span> {agencyName}
              </h1>
            </Imobiliaria>
            <Botao
              tipo="editar"
              tamanho="grande"
              className="btn"
              onClick={voltarTela}
            >
              Voltar
            </Botao>
          </DetalhesHeader>
          <h1>Dados:</h1>
          <DetalhesContent>
            <InputsContent>
              <InputsContainer>
                <InputField
                  tipo="formulario"
                  className="inputs"
                  label="Nome"
                  value={agencyName}
                  onChange={(e: any) => {
                    setAgencyName(e.target.value);
                  }}
                />
              </InputsContainer>
              <InputsContainer>
                <InputField
                  tipo="formulario"
                  className="inputs"
                  label="CNPJ"
                  value={agencyCnpj}
                  width={180}
                  onChange={setCNPJValue}
                />
              </InputsContainer>
              <InputsContainer>
                <InputField
                  tipo="formulario"
                  className="inputs"
                  label="Razão Social"
                  value={agencyRazaoSocial}
                  onChange={(e: any) => {
                    setAgencyRazaoSocial(e.target.value);
                  }}
                  width={200}
                />
              </InputsContainer>
              <InputsContainer>
                <InputField
                  tipo="formulario"
                  className="inputs"
                  label="Email"
                  value={agencyEmail}
                  onChange={(e: any) => {
                    setAgencyEmail(e.target.value);
                  }}
                />
              </InputsContainer>
              <InputsContainer>
                <InputField
                  tipo="formulario"
                  className="inputs"
                  label="Telefone"
                  value={agencyTelefone}
                  onChange={(e: any) => {
                    setAgencyTelefone(e.target.value);
                  }}
                />
              </InputsContainer>
              <InputsContainer>
                <InputField
                  tipo="formulario"
                  className="inputs"
                  label="Creci Jurídico"
                  value={creciJ}
                  onChange={(e: any) => {
                    console.log(creciJ)
                    setCreciJ(e.target.value);
                  }}
                />
              </InputsContainer>
              <InputsContainer>
                <InputField
                  tipo="formulario"
                  className="inputs"
                  label="Creci Físico"
                  value={creciF}
                  onChange={(e: any) => {
                    console.log(creciF)
                    setCreciF(e.target.value);
                  }}
                />
              </InputsContainer>
              <InputsContainer>
                <FormControl
                  style={{
                    width: 200,
                    marginLeft: 10,
                    // height: 40,
                  }}
                >
                  <InputLabel
                    id="demo-mutiple-checkbox-label"
                    style={{ marginTop: -10, marginLeft: 14 }}
                  >
                    Empreendimentos
                  </InputLabel>
                  {!allEnterprisesNames || !enterprisesNames ? (
                    ''
                  ) : (
                    <Select
                      labelId="demo-mutiple-checkbox-label"
                      id="demo-mutiple-checkbox"
                      multiple
                      variant="outlined"
                      style={{ width: 200, height: 40 }}
                      value={enterprisesNames}
                      onChange={handleChange}
                      input={
                        <OutlinedInput
                          style={{ height: 40 }}
                          label="Empreendimentos"
                        />
                      }
                      renderValue={(selected: any) => selected.join(', ')}
                      MenuProps={MenuProps}
                    >
                      {allEnterprisesNames.map((enterprise: any) => (
                        <MenuItem key={enterprise} value={enterprise}>
                          <Checkbox
                            checked={enterprisesNames.indexOf(enterprise) > -1}
                          />
                          {/* {
                          enterprises.map((imobEnterprise: any)=>{

                            if(enterprise.id===imobEnterprise.id) {
                              return <Checkbox checked={true} />
                            }
                            else{
                              return <Checkbox checked={false} />
                            }
                          },
                        )} */}
                          <ListItemText primary={enterprise} />
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </FormControl>
              </InputsContainer>
            </InputsContent>
            <div className="endereco">
              <h2>Endereço: </h2>
            </div>
            <InputsContent>
              <InputsContainer>
                <InputField
                  tipo="formulario"
                  className="inputs"
                  label="CEP"
                  width={100}
                  onChange={autoComplete}
                  value={agencyCEP}
                />
              </InputsContainer>
              <InputsContainer>
                <InputField
                  tipo="formulario"
                  className="inputs"
                  label="Logradouro"
                  value={agencyLogradouro}
                  onChange={(e: any) => {
                    setAgencyLogradouro(e.target.value);
                  }}
                />
              </InputsContainer>
              <InputsContainer>
                <InputField
                  tipo="formulario"
                  className="inputs"
                  label="Numero"
                  value={agencyNumero}
                  onChange={(e: any) => {
                    setAgencyNumero(e.target.value);
                  }}
                />
              </InputsContainer>
              <InputsContainer>
                <InputField
                  tipo="formulario"
                  className="inputs"
                  label="Cidade"
                  value={agencyCidade}
                  onChange={(e: any) => {
                    setAgencyCidade(e.target.value);
                  }}
                />
              </InputsContainer>
              <InputsContainer>
                <InputField
                  tipo="formulario"
                  className="inputs"
                  label="Bairro"
                  value={agencyBairro}
                  onChange={(e: any) => {
                    setAgencyBairro(e.target.value);
                  }}
                />
              </InputsContainer>
              <InputsContainer>
                {/* <InputField
                  tipo="formulario"
                  className="inputs"
                  label="UF"
                  width={80}
                  value={agencyUF}
                  onChange={setUFValue}
                /> */}

                <Autocomplete
                  id="combo-box-demo"
                  options={ufOptions}
                  getOptionLabel={option => option.title}
                  style={{ width: 120 }}
                  value={agencyUF}
                  loading
                  loadingText="carregando"
                  onChange={setUFValue}
                  disableClearable
                  renderInput={params => (
                    <TextField
                      label="UF"
                      variant="outlined"
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      size="small"
                      error={false}
                      helperText=""
                    />
                  )}
                />
              </InputsContainer>
            </InputsContent>

            <div className="endereco">
              <h2>Observações: </h2>
            </div>

            <InputsContent>
              <InputsContainer className="observacao-input-container">
                <InputField
                  tipo="formulario"
                  className="inputs"
                  label="Observações"
                  value={agencyObservacao}
                  width={"100%"}
                  onChange={(e: any) => {
                    setAgencyObservacao(e.target.value);
                  }}
                />
              </InputsContainer>
            </InputsContent>


            <BotoesContainer>
              <Botao
                tipo="salvar"
                tamanho="grande"
                className="btn-salvar"
                isLoading={isLoading}
                onClick={onSubmit}
              >
                Salvar Imobiliária
              </Botao>
              <Botao
                tipo="deletar"
                tamanho="grande"
                className="btn-excluir"
                isLoading={isLoading}
                onClick={() => setDeleteModalOpen(true)}
              >
                Excluir Imobiliária
              </Botao>
            </BotoesContainer>
          </DetalhesContent>
        </DetalhesContainer>
        <Snackbars
          type={snackbarProps.type}
          handleClose={handleSnackbarClose}
          open={snackbarOpen}
        >
          {snackbarProps.message}
        </Snackbars>
        <DeleteModal
          isModalOpen={deleteModalOpen}
          onCloseModal={() => setDeleteModalOpen(false)}
          onDeleteClick={() => deletarImobiliaria()}
        />
      </Content>
    </>
  );
};

export default EditarImobiliaria;
