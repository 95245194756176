/* eslint-disable no-restricted-syntax */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
/* eslint-disable no-await-in-loop */
/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useState } from 'react';
import {
  TextField,
  Fab,
  DialogContent,
  CircularProgress,
  Dialog,
  Typography,
} from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import UpdateIcon from '@material-ui/icons/Update';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { FaCheck, FaTimes } from 'react-icons/fa';

import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import ApprovedModal from '../../../components/ApprovedModal/ApprovedModal';
import InputField from '../../../components/InputsRefact/InputField';
import PessoaFisica from '../PessoaFisica';
import PropostasService from '../../../services/PropostasService';
import Snackbars from '../../../components/Snackbars';
import Botao from '../../../components/Botao/Botao';
import CancelChoiceModal from '../../../components/CancelChoiceModal';
import DisapprovedModal from '../../../components/DisapprovedModal/DisapprovedModal';
import VencerModal from '../../../components/VencerModal';
import AppError from '../../../errors/AppError';
import EmpreendimentosService from '../../../services/EmpreendimentosService';
import CommissionSalesService from '../../../services/CommissionSalesService';
import ImobiliariasService from '../../../services/ImobiliariasService';
import LotesService from '../../../services/LotesService';
import DocumentsSendBoleto from '../../AcompanhamentoProposta/DocumentsSendBoleto';
import DocumentsSendContrato from '../../AcompanhamentoProposta/DocumentsSendContrato';
import {
  Content,
  DetalhesContainer,
  DetalhesContent,
  DetalhesHeader,
  InputsContainer,
  InputsContent,
  SessionContente,
} from './styles';
import ModalGeneric from '../../../components/ModalGeneric/ModalGeneric';
import CancelIfInputModal from '../../../components/CancelIfInputModal';

interface Participante {
  id: string;
  index: number;
  cargo: string;
  cpf: string;
  data_nascimento: string;
  email: string;
  estado_civil: string;
  nacionalidade: string;
  nome: string;
  orgao_rg: string;
  porcentagem: number;
  rg: string;
  sexo: string;
  telefone: string;
  tipo_participante: string;
  cep: string;
  uf: string;
  municipio: string;
  bairro: string;
  logradouro: string;
  numero: string;
  complemento: string;
}

interface Lote {
  lote: string;
  quadra: string;
  empreendimento: string;
}

interface AtualizaParticipante {
  nome: string;
  sexo: string;
  rg: string;
  orgao_rg: string;
  email: string;
  nacionalidade: string;
  cep: string;
  uf: string;
  cidade: string;
  bairro: string;
  logradouro: string;
  numero: string;
  complemento: string;
}

interface AtualizaSinal {
  quantidadeSinal: number;
  dataVencimentoSinal: string;
  oldDataVencimentoSinal: string;
  participants: Participante[];
}

interface Campo {
  label: string;
  icon: JSX.Element | null;
  checkValue: boolean | null;
}

interface Balao {
  valor: string;
  quantidade: any;
  data: string;
}
interface AnalysisData {
  nome?: boolean;
  sexo?: boolean;
  estado_civil?: boolean;
  cpf?: boolean;
  rg?: boolean;
  orgao_emissor?: boolean;
  telefone?: boolean;
  email?: boolean;
  data_nascimento?: boolean;
  nacionalidade?: boolean;
  renda_apurada?: boolean;
  logradouro?: boolean;
  numero?: boolean;
  bairro?: boolean;
  cidade?: boolean;
  uf?: boolean;
  cep?: boolean;
}

interface ContratoData {
  nome_cliente: string;
  data_contrato: string;
  id_contrato: string;
  imobiliaria: string;
  inadimplente: string;
  lote: string;
  quadra: string;
  nome_empreendimento: string;
  status_contrato: string;
  tier: string;
}

function formatCurrency(value: any) {
  if (typeof value === 'string') {
    value = Number(value);
  }
  if (Number.isInteger(value)) {
    return value.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  } else {
    value = Number(value.toFixed(2));
    return value.toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  }
}

const formatCurrencyParcela = (value: string) => {
  if (!value) return '';

  const valorNumero = parseFloat(value.replace(',', '.'));
  return valorNumero.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
  });
};

function formataData(data: string) {
  const dataFormatada = new Date(data);
  return `${dataFormatada.toLocaleDateString()} às  ${dataFormatada.toLocaleTimeString()}`;
}

const formIAStyles = makeStyles(theme => ({
  textField: {
    backgroundColor: '#fff',
    borderRadius: 4,
  },
  successInput: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#0aa82f',
      },
      '&:hover fieldset': {
        borderColor: '#0aa82f',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#6b3fa0',
      },
      '&.Mui-disabled fieldset': {
        borderColor: '#0aa82f',
      },
      '&.Mui-disabled': {
        color: 'black',
      },
      '& input.Mui-disabled': {
        color: 'black',
      },
    },
  },
  warnInput: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#FFA500', // Cor laranja
      },
      '&:hover fieldset': {
        borderColor: '#FFA500',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#FFA500',
      },
      '&.Mui-disabled fieldset': {
        borderColor: '#FFA500', // Cor laranja
      },
    },
  },
  errorInput: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#FF0000', // Cor vermelha
      },
      '&:hover fieldset': {
        borderColor: '#FF0000',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#FF0000',
      },
    },
  },
}));

const EditarProposta: React.FC = () => {
  const [values, setValues] = useState<Participante[]>([]);
  const [signedFiles, setSignedFiles] = useState<any>([]);
  const [proposalStatus, setProposalStatus] = useState();
  const [imobiliaria, setImobiliaria] = useState('');
  const [lote, setLote] = useState<Lote>();
  const [isApproved, setIsApproved] = useState<boolean>(false);
  const [isApprovedModalLoading, setIsApprovedModalLoading] =
    useState<boolean>(false);
  const [isDisapproved, setIsDisapproved] = useState<boolean>(false);
  const [isDisapprovedProposal, setDisableProposal] = useState<boolean>(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [codMega, setCodMega] = useState('');
  const [landId, setLandId] = useState('');
  const [oldDataVencimentoSinal, setOldDataVencimentoSinal] =
    useState<string>('');
  const [dataVencimentoSinal, setDataVencimentoSinal] = useState<string>('');
  const [dataVencimentoParcelas, setDataVencimentoParcelas] =
    useState<string>('');
  const [moradiaAtual, setMoradiaAtual] = useState<string>('');
  const [rendaFamiliar, setRendaFamiliar] = useState<string>('');
  const [dependentes, setDependentes] = useState<string>('');
  const [objetivo, setObjetivo] = useState<string>('');
  const [numMoradores, setNumMoradores] = useState<string>('');
  const [nomeCorretor, setNomeCorretor] = useState<string>('');
  const [telefoneCorretor, setTelefoneCorretor] = useState<string>('');
  const [empCod, setEmpCod] = useState<string>('');

  const [selectedReason, setSelectedReason] = useState('');
  const [empElegivelPagadoria, setEmpElegivelPagadoria] =
    useState<boolean>(false);
  const [isBoletosPagadoriaGerados, setIsBoletosPagadoriaGerados] =
    useState<boolean>(false);
  const [isBoletosPagadoriaLiberados, setIsBoletosPagadoriaLiberados] =
    useState<boolean>(false);

  const [choiseModal, setChoiseModal] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [vencerModal, setVencerModal] = useState(false);
  const location = useLocation<any>();
  const [participantProposals, setParticipantProposals] = useState<any[]>([]);
  const [financialSeriesData, setFinancialSeriesData] = useState<any>('');
  const [snackbarProps, setSnackbarProps] = useState({
    message: 'Atualizado com Sucesso',
    type: 'success',
  });
  const [statusValue, setStatusValue] = useState<any>();
  const [dataCV, setDataCV] = useState<any>('');
  const [observacao, setObservacao] = useState<string>('');
  const [updatedFiles, setUpdatedFiles] = useState<any>();
  const {
    atualizacao,
    clt,
    criacao,
    data_vencimento_reduzidas,
    documentos,
    escolaridade,
    id,
    id_participante,
    num_entradas,
    num_parcelas,
    valor_entrada,
    valor_parcelas,
    valor_reduzidas,
    valor_total,
  } = location.state.rowData;
  const [valorParcela, setValorParcela] = useState<any>(valor_parcelas);
  const [valorEntrada, setValorEntrada] = useState<any>(valor_entrada);
  const [quantidadeParcelas, setQuantidadeParcelas] =
    useState<any>(num_parcelas);
  const [baloesAnuais, setBaloesAnuais] = useState<Balao[]>([]);
  const [baloesUnicos, setBaloesUnicos] = useState<Balao[]>([]);
  const [quantidadeSinal, setQuantidadeSinal] = useState(num_entradas);
  const [mostrarHistorico, setMostrarHistorico] = useState(false);
  const [contDocumentosPhysical, setContDocumentosPhysical] = useState<any>([]);
  const [participantDocumentos, setParticipantDocumentos] = useState<any[]>([]);
  const [isfaltaDocumentos, setIsfaltaDocumentos] = useState<boolean>(false);
  const [openLoadingIA, setLoadingIA] = useState(false);
  const [loadingUpdateVimob, setLoadingUpdateVimob] = useState(false);
  const [validationModalIASuccessMSG, SetValidationModalIASuccessMSG] =
    useState<{
      cpf: string;
      rg: string;
      nascimento: string;
      orgao_emissor: string;
      estado_civil: string;
      cep: string;
      uf: string;
      cidade: string;
      bairro: string;
      logradouro: string;
      numero: string;
      complemento: string;
    }>({
      cpf: '',
      rg: '',
      nascimento: '',
      orgao_emissor: '',
      estado_civil: '',
      cep: '',
      uf: '',
      cidade: '',
      bairro: '',
      logradouro: '',
      numero: '',
      complemento: '',
    });
  const [validationModalIASuccess, setValidationModalIASuccess] = useState<{
    rg: string;
    orgao_rg: string;
    cpf: string;
    nascimento: string;
    estado_civil: string;
    cep: string;
    uf: string;
    cidade: string;
    bairro: string;
    logradouro: string;
    numero: string;
    complemento: string;
  }>({
    rg: '',
    orgao_rg: '',
    cpf: '',
    nascimento: '',
    estado_civil: '',
    cep: '',
    uf: '',
    cidade: '',
    bairro: '',
    logradouro: '',
    numero: '',
    complemento: '',
  });
  const [nomeClienteSelecionado, setNomeClienteSelecionado] = useState('');
  const [newUserInfo, setNewUserInfo] = useState({
    attachedDocuments: [],
  });
  const [temBalaoAnual, setTemBalaoAnual] = useState<boolean>(false);
  const [temBalaoUnico, setTemBalaoUnico] = useState<boolean>(false);
  const [principalDocumento, setPrincipalDocumento] = useState('');
  const [dadosClienteResumo, setDadosClienteResumo] = useState<ContratoData[]>(
    [],
  );
  const [principal, setPrincipal] = useState<any>([]);

  const classes = formIAStyles();

  const handleUpdateVimob = async (values: Participante[], codigoEmpreendimento:string) => {
    setLoadingUpdateVimob(true);
    
    const body = {...values[0], codigoEmpreendimento};
  
    try {
      const responseUpdatePropostaMega = await PropostasService.updatePropostaMega(body);

      if(responseUpdatePropostaMega.status !== 'success') {
        throw new AppError('Erro ao atualizar os dados no Vimob');
      }

      setSnackbarOpen(true);
      setSnackbarProps({message: 'Dados atualizados com sucesso!', type: 'success'});
    } catch (error) {
      setSnackbarOpen(true);
      setSnackbarProps({message: 'Ocorreu um erro ao atualizar os dados.', type: 'error'});
    }
    finally {
      setLoadingUpdateVimob(false);
    }
  };

  const getInputClass = (tipo: string) => {
    if (tipo === 'sucess') {
      return classes.successInput;
    }
    if (tipo === 'warning') {
      return classes.warnInput;
    }
    if (tipo === 'error') {
      return classes.errorInput;
    }
    return '';
  };

  const handleLoadingIA = async () => {
    try {
      const response = await axios.get(
        'https://api-documentos.bairru.com.br/chatGpt/dados_analise_banco/',
        {
          params: {
            id_mega: codMega,
          },
          timeout: 180000,
        },
      );

      if (response.data.documentos_pessoais !== false) {
        // Validação do numero de RG
        if (response.data.documentos_pessoais.documento.rg !== false) {
          if (values[0].rg === '000' || values[0].rg === '') {
            setValues(prevValues =>
              prevValues.map((participante, index) =>
                index === 0
                  ? {
                      ...participante,
                      rg: response.data.documentos_pessoais.documento.rg,
                    }
                  : participante,
              ),
            );
            setValidationModalIASuccess(prevValues => ({
              ...prevValues,
              rg: 'sucess',
            }));
            SetValidationModalIASuccessMSG(prevValues => ({
              ...prevValues,
              rg: 'Preenchido automaticamente',
            }));
          } else if (
            String(values[0].rg).replace(/\D/g, '') ===
            String(response.data.documentos_pessoais.documento.rg).replace(
              /\D/g,
              '',
            )
          ) {
            setValidationModalIASuccess(prevValues => ({
              ...prevValues,
              rg: 'sucess',
            }));
            SetValidationModalIASuccessMSG(prevValues => ({
              ...prevValues,
              rg: 'RG confere com os documentos',
            }));
          } else if (
            String(values[0].rg).replace(/\D/g, '') !==
            String(response.data.documentos_pessoais.documento.rg).replace(
              /\D/g,
              '',
            )
          ) {
            setValidationModalIASuccess(prevValues => ({
              ...prevValues,
              rg: 'warning',
            }));
            SetValidationModalIASuccessMSG(prevValues => ({
              ...prevValues,
              rg: `RG diferente do encontrado na analise (${response.data.documentos_pessoais.documento.rg})`,
            }));
          }
        }

        // Validação do Orgão Emissor
        if (
          response.data.documentos_pessoais.documento.orgao_emissor !== false
        ) {
          if (values[0].orgao_rg === '...' || values[0].orgao_rg === '') {
            setValues(prevValues =>
              prevValues.map((participante, index) =>
                index === 0
                  ? {
                      ...participante,
                      orgao_rg: `${response.data.documentos_pessoais.documento.orgao_emissor} ${response.data.documentos_pessoais.documento.uf}`,
                    }
                  : participante,
              ),
            );
            setValidationModalIASuccess(prevValues => ({
              ...prevValues,
              orgao_rg: 'sucess',
            }));
            SetValidationModalIASuccessMSG(prevValues => ({
              ...prevValues,
              orgao_emissor: 'Preenchido automaticamente',
            }));
          }
        }

        // Validação do campo de CPF
        if (response.data.documentos_pessoais.documento.cpf !== false) {
          if (
            String(values[0].cpf).replace(/\D/g, '') ===
            String(response.data.documentos_pessoais.documento.cpf).replace(
              /\D/g,
              '',
            )
          ) {
            setValidationModalIASuccess(prevValues => ({
              ...prevValues,
              cpf: 'sucess',
            }));
            SetValidationModalIASuccessMSG(prevValues => ({
              ...prevValues,
              cpf: 'CPF confere com os documentos',
            }));
          }
        }

        // Validação da data de nascimento
        if (response.data.documentos_pessoais.documento.nascimento !== false) {
          const nascimentoString =
            response.data.documentos_pessoais.documento.nascimento;

          const [year, month, day] = nascimentoString.split('-');
          const formattedDate = `${day}/${month}/${year}`;

          const data = new Date(values[0].data_nascimento);

          // Formatar a data no formato DD/MM/AAAA
          const dayForm = String(data.getUTCDate()).padStart(2, '0');
          const monthForm = String(data.getUTCMonth() + 1).padStart(2, '0');
          const yearForm = data.getUTCFullYear();

          const formattedDateForm = `${dayForm}/${monthForm}/${yearForm}`;

          if (formattedDateForm === formattedDate) {
            setValidationModalIASuccess(prevValues => ({
              ...prevValues,
              nascimento: 'sucess',
            }));
            SetValidationModalIASuccessMSG(prevValues => ({
              ...prevValues,
              nascimento: 'Data de nascimento confere com os documentos',
            }));
          } else if (formattedDateForm !== formattedDate) {
            setValidationModalIASuccess(prevValues => ({
              ...prevValues,
              nascimento: 'warning',
            }));
            SetValidationModalIASuccessMSG(prevValues => ({
              ...prevValues,
              nascimento: `Data de nascimento diferente do encontrado na análise (${formattedDate})`,
            }));
          }
        }
      }
      // Validação do Estado Civil
      if (response.data.estado_civil !== false) {
        if (response.data.estado_civil.documento.estado_civil !== false) {
          if (
            values[0].estado_civil.toLocaleLowerCase() !==
            response.data.estado_civil.documento.estado_civil.toLocaleLowerCase()
          ) {
            setValidationModalIASuccess(prevValues => ({
              ...prevValues,
              estado_civil: 'warning',
            }));
            SetValidationModalIASuccessMSG(prevValues => ({
              ...prevValues,
              estado_civil: `Estado Civil diferente do encontrado na análise foi: ${response.data.estado_civil.documento.estado_civil} `,
            }));
          } else {
            setValidationModalIASuccess(prevValues => ({
              ...prevValues,
              estado_civil: 'sucess',
            }));
            SetValidationModalIASuccessMSG(prevValues => ({
              ...prevValues,
              estado_civil: 'Estado Civil confere com os documentos',
            }));
          }
        }
      }

      if (response.data.endereco !== false) {
        // Verificação do CEP
        if (values[0].cep === '' || values[0].cep === '...') {
          setValues(prevValues =>
            prevValues.map((participante, index) =>
              index === 0
                ? { ...participante, cep: response.data.endereco.documento.cep }
                : participante,
            ),
          );
          setValidationModalIASuccess(prevValues => ({
            ...prevValues,
            cep: 'sucess',
          }));
          SetValidationModalIASuccessMSG(prevValues => ({
            ...prevValues,
            cep: 'Preenchido automaticamente',
          }));
        } else if (
          response.data.endereco.documento.cep !== false &&
          values[0].cep !== response.data.endereco.documento.cep
        ) {
          setValidationModalIASuccess(prevValues => ({
            ...prevValues,
            cep: 'warning',
          }));
          SetValidationModalIASuccessMSG(prevValues => ({
            ...prevValues,
            cep: `CEP diferente do encontrado na análise (${response.data.endereco.documento.cep})`,
          }));
        } else {
          setValidationModalIASuccess(prevValues => ({
            ...prevValues,
            cep: 'sucess',
          }));
          SetValidationModalIASuccessMSG(prevValues => ({
            ...prevValues,
            cep: 'CEP confere com os documentos',
          }));
        }

        if (values[0].uf === '' || values[0].uf === '...') {
          setValues(prevValues =>
            prevValues.map((participante, index) =>
              index === 0
                ? { ...participante, uf: response.data.endereco.documento.uf }
                : participante,
            ),
          );
          setValidationModalIASuccess(prevValues => ({
            ...prevValues,
            uf: 'sucess',
          }));
          SetValidationModalIASuccessMSG(prevValues => ({
            ...prevValues,
            uf: 'Preenchido automaticamente',
          }));
        } else if (
          response.data.endereco.documento.uf !== false &&
          values[0].uf.toLowerCase() !==
            response.data.endereco.documento.uf.toLowerCase()
        ) {
          setValidationModalIASuccess(prevValues => ({
            ...prevValues,
            uf: 'warning',
          }));
          SetValidationModalIASuccessMSG(prevValues => ({
            ...prevValues,
            uf: `UF diferente do encontrado na análise: (${response.data.endereco.documento.uf})`,
          }));
        } else {
          setValidationModalIASuccess(prevValues => ({
            ...prevValues,
            uf: 'sucess',
          }));
          SetValidationModalIASuccessMSG(prevValues => ({
            ...prevValues,
            uf: 'UF confere com os documentos',
          }));
        }

        if (values[0].municipio === '' || values[0].municipio === '...') {
          setValues(prevValues =>
            prevValues.map((participante, index) =>
              index === 0
                ? {
                    ...participante,
                    cidade: response.data.endereco.documento.cidade,
                  }
                : participante,
            ),
          );
          setValidationModalIASuccess(prevValues => ({
            ...prevValues,
            cidade: 'sucess',
          }));
          SetValidationModalIASuccessMSG(prevValues => ({
            ...prevValues,
            cidade: 'Preenchido automaticamente',
          }));
        } else if (
          response.data.endereco.documento.cidade !== false &&
          values[0].municipio.toLowerCase() !==
            response.data.endereco.documento.cidade.toLowerCase()
        ) {
          setValidationModalIASuccess(prevValues => ({
            ...prevValues,
            cidade: 'warning',
          }));
          SetValidationModalIASuccessMSG(prevValues => ({
            ...prevValues,
            cidade: `Cidade diferente do encontrado na análise: (${response.data.endereco.documento.cidade})`,
          }));
        } else {
          setValidationModalIASuccess(prevValues => ({
            ...prevValues,
            cidade: 'sucess',
          }));
          SetValidationModalIASuccessMSG(prevValues => ({
            ...prevValues,
            cidade: 'Municipio confere com os documentos',
          }));
        }

        if (values[0].bairro === '' || values[0].bairro === '...') {
          setValues(prevValues =>
            prevValues.map((participante, index) =>
              index === 0
                ? {
                    ...participante,
                    bairro: response.data.endereco.documento.bairro,
                  }
                : participante,
            ),
          );
          setValidationModalIASuccess(prevValues => ({
            ...prevValues,
            bairro: 'sucess',
          }));
          SetValidationModalIASuccessMSG(prevValues => ({
            ...prevValues,
            bairro: 'Preenchido automaticamente',
          }));
        } else if (
          response.data.endereco.documento.bairro !== false &&
          values[0].bairro.toLowerCase() !==
            response.data.endereco.documento.bairro.toLowerCase()
        ) {
          setValidationModalIASuccess(prevValues => ({
            ...prevValues,
            bairro: 'warning',
          }));
          SetValidationModalIASuccessMSG(prevValues => ({
            ...prevValues,
            bairro: `Bairro diferente do encontrado na análise: (${response.data.endereco.documento.bairro})`,
          }));
        } else {
          setValidationModalIASuccess(prevValues => ({
            ...prevValues,
            bairro: 'sucess',
          }));
          SetValidationModalIASuccessMSG(prevValues => ({
            ...prevValues,
            bairro: 'Bairro confere com os documentos',
          }));
        }

        if (response.data.endereco.documento.logradouro !== false) {
          if (values[0].logradouro === '' || values[0].logradouro === '...') {
            setValues(prevValues =>
              prevValues.map((participante, index) =>
                index === 0
                  ? {
                      ...participante,
                      logradouro: response.data.endereco.documento.logradouro,
                    }
                  : participante,
              ),
            );
            setValidationModalIASuccess(prevValues => ({
              ...prevValues,
              logradouro: 'sucess',
            }));
          } else if (
            response.data.endereco.documento.logradouro !== false &&
            values[0].logradouro.toLowerCase() !==
              response.data.endereco.documento.logradouro.toLowerCase()
          ) {
            setValidationModalIASuccess(prevValues => ({
              ...prevValues,
              logradouro: 'warning',
            }));
            SetValidationModalIASuccessMSG(prevValues => ({
              ...prevValues,
              logradouro: `Logradouro diferente do encontrado na análise: (${response.data.endereco.documento.logradouro})`,
            }));
          } else {
            setValidationModalIASuccess(prevValues => ({
              ...prevValues,
              logradouro: 'sucess',
            }));
            SetValidationModalIASuccessMSG(prevValues => ({
              ...prevValues,
              logradouro: 'Logradouro confere com os documentos',
            }));
          }
        }

        if (response.data.endereco.documento.numero !== false) {
          if (values[0].numero === '' || values[0].numero === '...') {
            setValues(prevValues =>
              prevValues.map((participante, index) =>
                index === 0
                  ? {
                      ...participante,
                      numero: response.data.endereco.documento.numero,
                    }
                  : participante,
              ),
            );
          } else if (
            response.data.endereco.documento.numero !== false &&
            values[0].numero !== response.data.endereco.documento.numero
          ) {
            setValidationModalIASuccess(prevValues => ({
              ...prevValues,
              numero: 'warning',
            }));
            SetValidationModalIASuccessMSG(prevValues => ({
              ...prevValues,
              numero: `Numero diferente do encontrado na análise: (${response.data.endereco.documento.numero})`,
            }));
          } else {
            setValidationModalIASuccess(prevValues => ({
              ...prevValues,
              numero: 'sucess',
            }));
            SetValidationModalIASuccessMSG(prevValues => ({
              ...prevValues,
              numero: 'Numero confere com os documentos',
            }));
          }
        }

        if (response.data.endereco.documento.complemento !== false) {
          if (values[0].complemento === '' || values[0].complemento === '...') {
            setValues(prevValues =>
              prevValues.map((participante, index) =>
                index === 0
                  ? {
                      ...participante,
                      complemento: response.data.endereco.documento.complemento,
                    }
                  : participante,
              ),
            );
          } else if (
            response.data.endereco.documento.complemento !== false &&
            values[0].complemento.toLowerCase() !==
              response.data.endereco.documento.complemento.toLowerCase()
          ) {
            setValidationModalIASuccess(prevValues => ({
              ...prevValues,
              complemento: 'warning',
            }));
            SetValidationModalIASuccessMSG(prevValues => ({
              ...prevValues,
              complemento: `Complemento diferente do encontrado na análise: (${response.data.endereco.documento.complemento})`,
            }));
          } else {
            setValidationModalIASuccess(prevValues => ({
              ...prevValues,
              complemento: 'sucess',
            }));
            SetValidationModalIASuccessMSG(prevValues => ({
              ...prevValues,
              complemento: 'Complemento confere com os documentos',
            }));
          }
        }
      }
    } catch (error) {
      console.error('Erro ao fazer a requisição:', error);
    } finally {
      setTimeout(() => {
        setLoadingIA(false);
      }, 4000); 
    }
  };

  const handleSnackbarClose = (
    event?: React.SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };
  const analysisResult = async (param: any) => {
    setIsApprovedModalLoading(true);
    await PropostasService.analysisResult(param)
      .then(() => {
        if (param.proposal_valid) {
          setSnackbarOpen(true);
          setSnackbarProps({
            message: `Proposta aprovada com sucesso!`,
            type: 'success',
          });
        } else if (!param.proposal_valid) {
          setSnackbarOpen(true);
          setSnackbarProps({
            message: `Inconsistência com sucesso!`,
            type: 'success',
          });
        }
      })
      .catch((err: any) => {
        let message = err;
        if (err.message.response.data.message) {
          message = err.message.response.data.message;
        }
        setSnackbarOpen(true);
        setSnackbarProps({
          message: `Erro ao salvar proposta: ${message}`,
          type: 'error',
        });
      });
    setIsApproved(false);
    setIsApprovedModalLoading(false);
  };
  const handleChange = (event: any, id: number) => {
    const updatedValues = values.map(value => {
      if (value.index === id) {
        return { ...value, [event.target.name]: event.target.value };
      }
      return value;
    });
    setValues(updatedValues);
  };

  const handleChangeDataVencimentosSinal = (e: any) => {
    let inputDate = e.target.value;

    inputDate = inputDate.replace(/\D/g, '');

    if (inputDate.length <= 2) {
      setDataVencimentoSinal(inputDate);
    } else if (inputDate.length <= 4) {
      setDataVencimentoSinal(`${inputDate.slice(0, 2)}/${inputDate.slice(2)}`);
    } else {
      setDataVencimentoSinal(
        `${inputDate.slice(0, 2)}/${inputDate.slice(2, 4)}/${inputDate.slice(
          4,
          8,
        )}`,
      );
    }
  };

  async function atualizaParticipantes(data: AtualizaParticipante, id: string) {
    const response = await PropostasService.updateParticipante(data, id)
      .then(data => {
        let resp = data;
      })
      .catch((err: any) => {
        setSnackbarProps({
          message: `Erro ao enviar documentos ${err}`,
          type: 'error',
        });
      });
    return response;
  }

  function parseDate(date: string): Date {
    const parts = date.split('/');
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1;
    const year = parseInt(parts[2], 10);

    const parsedDate = new Date(year, month, day);
    return parsedDate;
  }

  function verifyDataVencimentoSinal(oldDate: Date, newDate: Date): boolean {
    if (oldDate.getTime() === newDate.getTime()) {
      return true;
    }

    const oldYear = oldDate.getFullYear();
    const oldMonth = oldDate.getMonth();
    const oldDay = oldDate.getDate();

    const newYear = newDate.getFullYear();
    const newMonth = newDate.getMonth();
    const newDay = newDate.getDate();

    if (oldYear !== newYear || oldMonth !== newMonth) {
      setSnackbarOpen(true);
      setSnackbarProps({
        message: `Erro: A nova data de vencimento de sinal deve ser no mesmo mês da data antiga`,
        type: 'error',
      });
      setDataVencimentoSinal(oldDataVencimentoSinal);
      return false;
    }

    if (newDay > 25) {
      setSnackbarOpen(true);
      setSnackbarProps({
        message: `Erro: O dia da nova data de vencimento de sinal deve ser no máximo 25`,
        type: 'error',
      });
      setDataVencimentoSinal(oldDataVencimentoSinal);
      return false;
    }

    const daysDiff = newDay - oldDay;

    if (daysDiff < 0 || daysDiff > 10) {
      setSnackbarOpen(true);
      setSnackbarProps({
        message: `Erro: A nova data de vencimento de sinal deve ser no máximo 10 dias após a data antiga`,
        type: 'error',
      });
      setDataVencimentoSinal(oldDataVencimentoSinal);
      return false;
    }

    return true;
  }

  async function atualizaSinal(data: AtualizaSinal, id: string) {
    const dataVencimentoSinalParsed =
      data.dataVencimentoSinal === data.oldDataVencimentoSinal
        ? new Date(data.dataVencimentoSinal)
        : parseDate(data.dataVencimentoSinal);
    const oldDataVencimentoSinalParsed = new Date(data.oldDataVencimentoSinal);
    const dateIsCorrect = verifyDataVencimentoSinal(
      oldDataVencimentoSinalParsed,
      dataVencimentoSinalParsed,
    );

    if (!dateIsCorrect) {
      return;
    }

    await PropostasService.updateProposta(
      {
        num_entradas: data.quantidadeSinal,
        data_vencimento_sinal: dataVencimentoSinalParsed,
        proposals_participants: data.participants,
      },
      id,
    )
      .then(() => {
        setOldDataVencimentoSinal(dataVencimentoSinalParsed.toISOString());
      })
      .catch((err: any) => {
        setSnackbarProps({
          message: `Erro ao atualizar proposta: ${err}`,
          type: 'error',
        });
      });
  }

  const handleSave = () => {
    values.map((value: any) => {
      const data = {
        nome: value.nome,
        sexo: value.sexo,
        rg: value.rg,
        orgao_rg: value.orgao_rg,
        email: value.email,
        nacionalidade: value.nacionalidade,
        cep: value.cep,
        uf: value.uf,
        cidade: value.municipio,
        bairro: value.bairro,
        logradouro: value.logradouro,
        numero: value.numero,
        complemento: value.complemento,
      };
      atualizaParticipantes(data, value.id);
      return true;
    });
    if (empElegivelPagadoria) {
      atualizaSinal(
        {
          quantidadeSinal: quantidadeSinal,
          dataVencimentoSinal: dataVencimentoSinal,
          oldDataVencimentoSinal: oldDataVencimentoSinal,
          participants: values,
        },
        id,
      );
    }
  };

  async function changeStatus(
    status: string,
    cod_mega: string,
    comentary?: string,
  ) {
    if (!comentary) {
      comentary = '';
    }
    await PropostasService.defeatProposal(location.state.rowData.id, {
      cod_mega: cod_mega,
      status: status,
      comentary: comentary,
    })
      .then(() => {
        setSnackbarOpen(true);
        setSnackbarProps({
          message: `${status} com sucesso!`,
          type: 'success',
        });
      })
      .catch((err: any) => {
        setSnackbarProps({
          message: `${err}`,
          type: 'error',
        });
      });
  }

  const handleReasonChange = (reason: string) => {
    setSelectedReason(reason);
  };

  async function findImobName(id: any) {
    const response = await ImobiliariasService.findImobiliaria(id);
    return response.agency.nome;
  }

  const getDataCV = async (id: string) => {
    const dataCVByID = await PropostasService.getDataCV(id);
    if (dataCVByID) {
      setDataCV(dataCVByID);
    }
    return null;
  };

  async function findLand(id: any) {
    const response = await LotesService.findLote(id);
    return response.land;
  }

  async function getProposalPhysical() {
    await PropostasService.getProposalPhysicalById(id).then(response => {
      const res = response.proposal;
      setNomeCorretor(res.user.name);
      setTelefoneCorretor(res.user.phone);
      findImobName(res.user.agency_id).then((resp: any) => {
        setImobiliaria(resp);
      });
      findLand(res.land_id).then((resp: any) => {
        setLote(resp);
      });
      setCodMega(res.cod_mega_proposta_ativa);
      setLandId(res.land_id);
      setDataVencimentoSinal(res.data_vencimento_sinal);
      setOldDataVencimentoSinal(res.data_vencimento_sinal);
      setDataVencimentoParcelas(res.data_vencimento_parcela);
      setMoradiaAtual(res.moradia_atual);
      setRendaFamiliar(res.renda_familiar);
      setDependentes(res.num_filhos);
      setObjetivo(res.objetivo);
      setNumMoradores(res.numero_pessoas_morar);
      setObservacao(res.comentary);
      setPrincipalDocumento(res.cpf);
      setQuantidadeSinal(res.num_entradas);
      setEmpCod(res.emp_cod);
      const participante = res.proposals_participants.map(
        (participants: any, index: number) => {
          let tipo = {
            id: '',
            id_participante: '',
            id_proposta: '',
            porcentagem: '100',
            tipo_participante: 'principal',
          };
          if (res.proposals_participants_type_percentage.length > 1) {
            tipo = res.proposals_participants_type_percentage.find(
              (type: any) => type.id_participante === participants.id,
            );
          }

          return {
            id: participants.id,
            index: index,
            cargo: participants.cargo,
            cpf: participants.cpf,
            data_nascimento: participants.data_nascimento,
            email: participants.email,
            estado_civil: participants.estado_civil,
            nacionalidade: participants.nacionalidade,
            nome: participants.nome,
            orgao_rg: participants.orgao_rg,
            rg: participants.rg,
            sexo: participants.sexo,
            telefone: participants.telefone,
            tipo_participante: tipo.tipo_participante,
            porcentagem: tipo.porcentagem,
            cep: participants.cep,
            uf: participants.uf,
            municipio: participants.cidade,
            bairro: participants.bairro,
            logradouro: participants.logradouro,
            numero: participants.numero,
            complemento: participants.complemento,
          };
        },
      );
      setValues(
        participante.sort((a: any, b: any) =>
          a.tipo_participante.localeCompare(b.tipo_participante),
        ),
      );

      const principal = participante.sort((a: any, b: any) =>
        a.tipo_participante.localeCompare(b.tipo_participante),
      );

      getDataCV(id);
    });
  }

  async function getEnterprise() {
    if (empCod.length > 0) {
      await EmpreendimentosService.findEmpreendimentoByCodEmpreendimento(
        empCod,
      ).then(response => {
        const res = response.enterprise;
        setEmpElegivelPagadoria(res.elegivel_pagadoria);
      });
    }
  }

  async function getWeBroPaySales() {
    if (empElegivelPagadoria && codMega.length > 0) {
      await CommissionSalesService.getWebropaySales(codMega).then(response => {
        const sales = response.weBroPaySales;
        setIsBoletosPagadoriaGerados(sales.length > 0);
        setIsBoletosPagadoriaLiberados(
          sales.length > 0 &&
            sales.every(sale => sale.pagamentoLiberado === 'Sim'),
        );
      });
    }
  }

  useEffect(() => {
    getProposalPhysical();
  }, []);

  useEffect(() => {
    getEnterprise();
  }, [empCod]);

  useEffect(() => {
    getWeBroPaySales();
  }, [empElegivelPagadoria, codMega]);

  useEffect(() => {
    if (statusValue !== 'Aprovado') {
      handleLoadingIA();
    }
  }, [imobiliaria]);

  const mudaSituacao = async (id_reserva: any, situacao_destino: number) => {
    if (!id_reserva) {
      return;
    }
    await PropostasService.moveCardReserva(id_reserva, situacao_destino);
  };

  const formatFileName = async (files: any[], codMega: any) => {
    let arrReturn: any = [];

    files.forEach((el: any, index: number) => {
      let newFileName = '';

      const tiposPermitidos = ['pdf', 'png', 'jpeg', 'jpg', 'doc', 'docx'];

      if (!tiposPermitidos.includes(el.file.type.split('/')[1])) {
        setSnackbarProps({
          message: 'Tipo de arquivo não permitido!',
          type: 'error',
        });
        setSnackbarOpen(true);
        return;
      }

      if (el.tag === 'personal') {
        newFileName = `${codMega}_personal${index + 1}.${
          el.file.type.split('/')[1]
        }`;
      } else if (el.tag === 'comp_residencial') {
        newFileName = `${codMega}_comp_res${index + 1}.${
          el.file.type.split('/')[1]
        }`;
      } else if (el.tag === 'estado_civil') {
        newFileName = `${codMega}_estado_civil${index + 1}.${
          el.file.type.split('/')[1]
        }`;
      } else if (el.tag === 'comp_renda') {
        newFileName = `${codMega}_comp_renda${index + 1}.${
          el.file.type.split('/')[1]
        }`;
      } else if (el.tag === 'devolution_boleto') {
        newFileName = `${codMega}_devolution_boleto${index + 1}.${
          el.file.type.split('/')[1]
        }`;
      } else if (el.tag === 'signed_cp') {
        newFileName = `${codMega}_signed_cp${index + 1}.${
          el.file.type.split('/')[1]
        }`;
      } else if (el.tag === 'devolution_payment_platform') {
        newFileName = `${codMega}_devolution_payment_platform${index + 1}.${
          el.file.type.split('/')[1]
        }`;
      } else if (el.tag === 'devolution_contrato') {
        newFileName = `${codMega}_devolution_contrato${index + 1}.${
          el.file.type.split('/')[1]
        }`;
      } else if (el.tag === 'devolution') {
        newFileName = `${codMega}_devolution${index + 1}.${
          el.file.type.split('/')[1]
        }`;
      } else if (el.tag === 'boleto_comissao') {
        newFileName = `${codMega}_boleto_comissao${index + 1}.${
          el.file.type.split('/')[1]
        }`;
      }

      const newFile = new File([el.file], newFileName, {
        type: el.file.type,
        lastModified: el.file.lastModified,
      });

      arrReturn.push({ tag: el.tag, file: newFile });
    });

    return arrReturn;
  };

  const files = location.state.rowData.documentos.map((doc: any) => {
    let documentSelectName = doc.document;
    const isPaymentPlatformDevolution = doc.tag.includes(
      'devolution_payment_platform',
    );
    if (isPaymentPlatformDevolution) {
      const numeroBoleto = doc.tag[doc.tag.length - 1];
      documentSelectName = `boleto_comissao_${numeroBoleto}`;
    } else {
      documentSelectName = doc.document.split('/')[4].split('-');
      documentSelectName = documentSelectName.splice(1);
    }
    let principal = true;
    let docPessoal = 0;
    let docEstadoCivil = 0;
    let docDevolution = 0;
    let docDevolutionBoleto = 0;
    let docDevolutionContrato = 0;
    let docCompRenda = 0;
    let docCompRes = 0;
    const docName = isPaymentPlatformDevolution
      ? documentSelectName
      : documentSelectName.join('_');
    const tag = isPaymentPlatformDevolution ? 'devolution' : doc.tag;
    if (
      doc.tag === 'personal' ||
      doc.tag === 'signed_cp' ||
      doc.tag === 'signed-cp'
    ) {
      docPessoal += 1;
    }
    if (doc.tag === 'estado_civil') {
      docEstadoCivil += 1;
    }
    if (doc.tag === 'comp_renda') {
      docCompRenda += 1;
    }
    if (doc.tag === 'comp_residencial') {
      docCompRes += 1;
    }
    if (doc.tag === 'devolution_boleto') {
      docDevolutionBoleto += 1;
    }
    if (doc.tag === 'devolution_contrato') {
      docDevolutionContrato += 1;
    }
    return {
      id: doc.id,
      url: doc.document,
      name: docName,
      tag:
        doc.tag === 'signed_cp' || doc.tag === 'signed-cp'
          ? 'personal'
          : doc.tag,
      deletado: doc.deletado,
    };
  });
  files.sort((a: any, b: any) => a.name.localeCompare(b.name));

  async function salvarProposta() {
    setSnackbarProps({
      message: 'Carregando...',
      type: 'info',
    });
    setSnackbarOpen(true);
    try {
      let arrSeparatedByParticipantID = [];
      let existis;
      for (let el of participantDocumentos) {
        existis = arrSeparatedByParticipantID.findIndex(
          (e: any) => e.participant_id === el.participant_id,
        );
        existis = Number(existis);
        if (existis >= 0) {
          let aux = [
            ...arrSeparatedByParticipantID[existis].docs,
            ...[el.docs],
          ];
          arrSeparatedByParticipantID[existis].docs = aux;
        } else {
          arrSeparatedByParticipantID.push({
            participant_id: el.participant_id,
            docs: [el.docs],
          });
        }
      }
      for (let el of arrSeparatedByParticipantID) {
        let doc_pessoal: any = [];
        let doc_devolution: any = [];
        let doc_devolution_contrato: any = [];
        let doc_devolution_boleto: any = [];
        let doc_res: any = [];
        let doc_estado_civil: any = [];
        let doc_comp_renda: any = [];

        for (let doc of el.docs) {
          if (doc.tag === 'documentos pessoais') {
            doc_pessoal = doc.file;
          }
          if (doc.tag === 'comprovante de residencia') {
            doc_res = doc.file;
          }
          if (doc.tag === 'certidao estado civil') {
            doc_estado_civil = doc.file;
          }
          if (doc.tag === 'comp_renda') {
            doc_comp_renda = doc.file;
          }
          if (doc.tag === 'devolution') {
            doc_devolution = doc.file;
          }
          if (doc.tag === 'devolution-contrato') {
            doc_devolution_contrato = doc.file;
          }
          if (doc.tag === 'devolution-boleto') {
            doc_devolution_boleto = doc.file;
          }
        }
        let arrToRequest = [];

        if (doc_pessoal.length > 0) {
          for (let el of doc_pessoal) {
            arrToRequest.push({
              tag: 'personal',
              file: el,
              deletado: false,
            });
          }
        }
        if (doc_devolution.length > 0) {
          for (let el of doc_devolution) {
            arrToRequest.push({
              tag: 'devolution',
              file: el,
              deletado: false,
            });
          }
        }
        if (doc_devolution_boleto.length > 0) {
          for (let el of doc_devolution_boleto) {
            arrToRequest.push({
              tag: 'devolution_boleto',
              file: el,
              deletado: false,
            });
          }
        }
        if (doc_devolution_contrato.length > 0) {
          for (let el of doc_devolution_contrato) {
            arrToRequest.push({
              tag: 'devolution_contrato',
              file: el,
              deletado: false,
            });
          }
        }
        if (doc_estado_civil.length > 0) {
          for (let el of doc_estado_civil) {
            arrToRequest.push({
              tag: 'estado_civil',
              file: el,
              deletado: false,
            });
          }
        }

        if (doc_res.length > 0) {
          for (let el of doc_res) {
            arrToRequest.push({
              tag: 'comp_residencial',
              file: el,
              deletado: false,
            });
          }
        }

        if (doc_comp_renda.length > 0) {
          for (let el of doc_comp_renda) {
            arrToRequest.push({
              tag: 'comp_renda',
              file: el,
              deletado: false,
            });
          }
        }
        const hasDevolutionBoleto = arrToRequest.some(
          item => item.tag === 'devolution_boleto',
        );

        if (hasDevolutionBoleto) {
          mudaSituacao(dataCV[0]?.id_reserva, 34);
        }

        const formatedFiles = await formatFileName(arrToRequest, codMega);
        await PropostasService.updateDocumentsProposalPhysical(
          formatedFiles,
          location.state.rowData.id,
          el.participant_id,
        );
      }
      setSnackbarProps({
        message: 'Os documentos foram enviados com sucesso!',
        type: 'success',
      });
    } catch (err: any) {
      setSnackbarProps({
        message: `Status: ${err.message.response.status} - ${err.message.response.data.message}`,
        type: 'error',
      });
    }
  }

  const handleCancelClick = (reason: string) => {
    mudaSituacao(dataCV[0]?.id_reserva, 25);
    changeStatus(`Cancelada por ${reason}`, codMega);
    setIsCancelModalOpen(false);
  };

  const updateUploadedFiles = (
    files: any,
    dropdown = 'documentos pessoais',
    participantID: any,
  ) => {
    let oldPartID = '';
    let docArr = [];
    let docFlag = -1;
    switch (dropdown) {
      case 'documentos pessoais':
        for (let i = 0; i < participantDocumentos.length; i++) {
          if (
            participantDocumentos[i].docs.tag === 'documentos pessoais' &&
            participantDocumentos[i].participant_id === participantID
          ) {
            docFlag = i;
          }
        }
        if (docFlag >= 0) {
          participantDocumentos[docFlag].docs.file = files;
          setParticipantDocumentos(participantDocumentos);
        } else {
          setParticipantDocumentos((oldArray: any) => [
            ...oldArray,
            {
              participant_id: participantID,
              docs: {
                tag: 'documentos pessoais',
                file: files,
                deletado: false,
              },
            },
          ]);
        }
        const existisDocPessoal = contDocumentosPhysical.findIndex(
          (e: { participant_id: any }) => e.participant_id === participantID,
        );
        if (existisDocPessoal >= 0) {
          contDocumentosPhysical[existisDocPessoal].docPessoal = files.length;
          setContDocumentosPhysical(contDocumentosPhysical);
        } else {
          contDocumentosPhysical.push({
            participant_id: participantID,
            docPessoal: files.length,
            docDevolutionBoleto: 0,
            docDevolutionContrato: 0,
            docRenda: 0,
            docResidencia: 0,
            docEstadoCivil: 0,
          });
          setContDocumentosPhysical(contDocumentosPhysical);
        }
        break;
      case 'devolution':
        for (let i = 0; i < participantDocumentos.length; i++) {
          if (
            participantDocumentos[i].docs.tag === 'devolution' &&
            participantDocumentos[i].participant_id === participantID
          ) {
            docFlag = i;
          }
        }
        if (docFlag >= 0) {
          participantDocumentos[docFlag].docs.file = files;
          setParticipantDocumentos(participantDocumentos);
        } else {
          setParticipantDocumentos((oldArray: any) => [
            ...oldArray,
            {
              participant_id: participantID,
              docs: { tag: 'devolution', file: files, deletado: false },
            },
          ]);
        }
        const existeDocDevolution = contDocumentosPhysical.findIndex(
          (e: { participant_id: any }) => e.participant_id === participantID,
        );
        if (existeDocDevolution >= 0) {
          contDocumentosPhysical[existeDocDevolution].docPessoal = files.length;
          setContDocumentosPhysical(contDocumentosPhysical);
        } else {
          contDocumentosPhysical.push({
            participant_id: participantID,
            docDevolutionBoleto: 0,
            docDevolutionContrato: 0,
            docPessoal: 0,
            docDevolution: files.length,
            docRenda: 0,
            docResidencia: 0,
            docEstadoCivil: 0,
          });
          setContDocumentosPhysical(contDocumentosPhysical);
        }
        break;
      case 'Devolução boleto':
        for (let i = 0; i < participantDocumentos.length; i++) {
          if (
            participantDocumentos[i].docs.tag === 'Devolução boleto' &&
            participantDocumentos[i].participant_id === participantID
          ) {
            docFlag = i;
          }
        }
        if (docFlag >= 0) {
          participantDocumentos[docFlag].docs.file = files;
          setParticipantDocumentos(participantDocumentos);
        } else {
          setParticipantDocumentos((oldArray: any) => [
            ...oldArray,
            {
              participant_id: participantID,
              docs: { tag: 'devolution-boleto', file: files, deletado: false },
            },
          ]);
        }
        const existeDocDevolutionBoleto = contDocumentosPhysical.findIndex(
          (e: { participant_id: any }) => e.participant_id === participantID,
        );
        if (existeDocDevolutionBoleto >= 0) {
          contDocumentosPhysical[existeDocDevolutionBoleto].docPessoal =
            files.length;
          setContDocumentosPhysical(contDocumentosPhysical);
        } else {
          contDocumentosPhysical.push({
            participant_id: participantID,
            docDevolutionBoleto: files.length,
            docDevolutionContrato: 0,
            docPessoal: 0,
            docDevolution: 0,
            docRenda: 0,
            docResidencia: 0,
            docEstadoCivil: 0,
          });
          setContDocumentosPhysical(contDocumentosPhysical);
        }
        break;
      case 'Devolução contrato':
        for (let i = 0; i < participantDocumentos.length; i++) {
          if (
            participantDocumentos[i].docs.tag === 'Devolução contrato' &&
            participantDocumentos[i].participant_id === participantID
          ) {
            docFlag = i;
          }
        }
        if (docFlag >= 0) {
          participantDocumentos[docFlag].docs.file = files;
          setParticipantDocumentos(participantDocumentos);
        } else {
          setParticipantDocumentos((oldArray: any) => [
            ...oldArray,
            {
              participant_id: participantID,
              docs: {
                tag: 'devolution-contrato',
                file: files,
                deletado: false,
              },
            },
          ]);
        }
        const existeDocDevolutionContrato = contDocumentosPhysical.findIndex(
          (e: { participant_id: any }) => e.participant_id === participantID,
        );
        if (existeDocDevolutionContrato >= 0) {
          contDocumentosPhysical[existeDocDevolutionContrato].docPessoal =
            files.length;
          setContDocumentosPhysical(contDocumentosPhysical);
        } else {
          contDocumentosPhysical.push({
            participant_id: participantID,
            docDevolutionContrato: files.length,
            docDevolutionBoleto: 0,
            docPessoal: 0,
            docDevolution: 0,
            docRenda: 0,
            docResidencia: 0,
            docEstadoCivil: 0,
          });
          setContDocumentosPhysical(contDocumentosPhysical);
        }
        break;
      case 'certidao estado civil':
        for (let i = 0; i < participantDocumentos.length; i++) {
          if (
            participantDocumentos[i].docs.tag === 'certidao estado civil' &&
            participantDocumentos[i].participant_id === participantID
          ) {
            docFlag = i;
          }
        }
        if (docFlag >= 0) {
          participantDocumentos[docFlag].docs.file = files;
          setParticipantDocumentos(participantDocumentos);
        } else {
          setParticipantDocumentos((oldArray: any) => [
            ...oldArray,
            {
              participant_id: participantID,
              docs: {
                tag: 'certidao estado civil',
                file: files,
                deletado: false,
              },
            },
          ]);
        }
        const existisDocEstadoCivil = contDocumentosPhysical.findIndex(
          (e: { participant_id: any }) => e.participant_id === participantID,
        );
        if (existisDocEstadoCivil >= 0) {
          contDocumentosPhysical[existisDocEstadoCivil].docEstadoCivil =
            files.length;
          setContDocumentosPhysical(contDocumentosPhysical);
        } else {
          contDocumentosPhysical.push({
            participant_id: participantID,
            docDevolutionContrato: 0,
            docDevolutionBoleto: 0,
            docPessoal: 0,
            docDevolution: 0,
            docRenda: 0,
            docResidencia: 0,
            docEstadoCivil: files.length,
          });
          setContDocumentosPhysical(contDocumentosPhysical);
        }
        break;
      case 'comprovante de residencia':
        for (let i = 0; i < participantDocumentos.length; i++) {
          if (
            participantDocumentos[i].docs.tag === 'comprovante de residencia' &&
            participantDocumentos[i].participant_id === participantID
          ) {
            docFlag = i;
          }
        }
        if (docFlag >= 0) {
          participantDocumentos[docFlag].docs.file = files;
          setParticipantDocumentos(participantDocumentos);
        } else {
          setParticipantDocumentos((oldArray: any) => [
            ...oldArray,
            {
              participant_id: participantID,
              docs: {
                tag: 'comprovante de residencia',
                file: files,
                deletado: false,
              },
            },
          ]);
        }
        const existisDocCompRes = contDocumentosPhysical.findIndex(
          (e: { participant_id: any }) => e.participant_id === participantID,
        );
        if (existisDocCompRes >= 0) {
          contDocumentosPhysical[existisDocCompRes].docResidencia =
            files.length;
          setContDocumentosPhysical(contDocumentosPhysical);
        } else {
          contDocumentosPhysical.push({
            participant_id: participantID,
            docDevolutionBoleto: 0,
            docDevolutionContrato: 0,
            docPessoal: 0,
            docDevolution: 0,
            docRenda: 0,
            docResidencia: 0,
            docEstadoCivil: files.length,
          });
          setContDocumentosPhysical(contDocumentosPhysical);
        }
        break;
      case 'comp_renda':
        for (let i = 0; i < participantDocumentos.length; i++) {
          if (
            participantDocumentos[i].docs.tag === 'comp_renda' &&
            participantDocumentos[i].participant_id === participantID
          ) {
            docFlag = i;
          }
        }
        if (docFlag >= 0) {
          participantDocumentos[docFlag].docs.file = files;
          setParticipantDocumentos(participantDocumentos);
        } else {
          setParticipantDocumentos((oldArray: any) => [
            ...oldArray,
            {
              participant_id: participantID,
              docs: { tag: 'comp_renda', file: files, deletado: false },
            },
          ]);
        }
        const existisDocCompRenda = contDocumentosPhysical.findIndex(
          (e: { participant_id: any }) => e.participant_id === participantID,
        );
        if (existisDocCompRenda >= 0) {
          contDocumentosPhysical[existisDocCompRenda].docRenda = files.length;
          setContDocumentosPhysical(contDocumentosPhysical);
        } else {
          contDocumentosPhysical.push({
            participant_id: participantID,
            docDevolutionBoleto: 0,
            docDevolutionContrato: 0,
            docPessoal: 0,
            docDevolution: 0,
            docRenda: 0,
            docResidencia: 0,
            docEstadoCivil: files.length,
          });
          setContDocumentosPhysical(contDocumentosPhysical);
        }
        break;
      default:
        throw new AppError('Documento inválido');
    }
    setNewUserInfo({ ...newUserInfo, attachedDocuments: files });
  };

  useEffect(() => {
    if (location.state.rowData) {
      setStatusValue(location.state.rowData.status);
    }
  }, [location.state.rowData]);

  async function getTokenAuth() {
    const response = await PropostasService.getTokenAuthClientResume();
    return response;
  }

  async function getDadosClienteResumo(): Promise<ContratoData[]> {
    try {
      const responseProposal = await PropostasService.getProposalPhysicalById(
        id,
      );
      const cpf = responseProposal.proposal.proposals_participants[0].cpf;
      const formattedCPF = cpf.replace(
        /^(\d{3})(\d{3})(\d{3})(\d{2})$/,
        '$1.$2.$3-$4',
      );

      const response = await PropostasService.getDataClientResume(formattedCPF);
      const contratosData: ContratoData[] = [];

      if (response.contratos) {
        for (const contratoId in response.contratos) {
          if (
            Object.prototype.hasOwnProperty.call(response.contratos, contratoId)
          ) {
            const contrato = response.contratos[contratoId];
            const contratoData: ContratoData = {
              nome_cliente: contrato.nome,
              data_contrato: contrato.data_contrato,
              id_contrato: contrato.id_contrato,
              imobiliaria: contrato.imobiliaria,
              inadimplente: contrato.inadimplente,
              lote: contrato.lote,
              quadra: contrato.quadra,
              nome_empreendimento: contrato.nome_empreendimento,
              status_contrato: contrato.status,
              tier: contrato.tier,
            };
            contratosData.push(contratoData);
          }
        }
        if (contratosData.length > 0) {
          setNomeClienteSelecionado(contratosData[0].nome_cliente);
        }
      }
      return contratosData;
    } catch (error) {
      throw new Error('Erro ao obter os dados do cliente resumo');
    }
  }

  useEffect(() => {
    getTokenAuth();
  }, []);

  useEffect(() => {
    async function fetchDadosClienteResumo() {
      const dados = await getDadosClienteResumo();
      setDadosClienteResumo(dados);
    }

    fetchDadosClienteResumo();
  }, []);

  function formatarData(data: string | number | Date) {
    const dataObj = new Date(data);
    const dia = String(dataObj.getDate()).padStart(2, '0');
    const mes = String(dataObj.getMonth() + 1).padStart(2, '0');
    const ano = dataObj.getFullYear();
    return `${dia}/${mes}/${ano}`;
  }

  const [resultadoAnalise, setResultadoAnalise] = useState([]);
  const [mensagemAnalise, setMensagemAnalise] = useState('Em Analise');

  const [iconNome, setIconNome] = useState<JSX.Element>(<></>);
  const [iconSexo, setIconSexo] = useState<JSX.Element>(<></>);
  const [iconEstadoCivil, setIconEstadoCivil] = useState<JSX.Element>(<></>);
  const [iconCPF, setIconCPF] = useState<JSX.Element>(<></>);
  const [iconRG, setIconRG] = useState<JSX.Element>(<></>);
  const [iconOrgaoEmissor, setIconOrgaoEmissor] = useState<JSX.Element>(<></>);
  const [iconTelefone, setIconTelefone] = useState<JSX.Element>(<></>);
  const [iconEmail, setIconEmail] = useState<JSX.Element>(<></>);
  const [iconDataNascimento, setIconDataNascimento] = useState<JSX.Element>(
    <></>,
  );
  const [iconNacionalidade, setIconNacionalidade] = useState<JSX.Element>(
    <></>,
  );
  const [iconRendaApurada, setIconRendaApurada] = useState<JSX.Element>(<></>);
  const [iconLogradouro, setIconLogradouro] = useState<JSX.Element>(<></>);
  const [iconNumero, setIconNumero] = useState<JSX.Element>(<></>);
  const [iconBairro, setIconBairro] = useState<JSX.Element>(<></>);
  const [iconCidade, setIconCidade] = useState<JSX.Element>(<></>);
  const [iconUf, setIconUf] = useState<JSX.Element>(<></>);
  const [iconCep, setIconCep] = useState<JSX.Element>(<></>);

  const [iconCargo, setIconCargo] = useState<JSX.Element>(<></>);
  const [iconCLT, setIconCLT] = useState<JSX.Element>(<></>);
  const [iconEscolaridade, setIconEscolaridade] = useState<JSX.Element>(<></>);
  const [iconMoradiaAtual, setIconMoradiaAtual] = useState<JSX.Element>(<></>);
  const [iconRendaFamiliar, setIconRendaFamiliar] = useState<JSX.Element>(
    <></>,
  );
  const [iconDependentes, setIconDependentes] = useState<JSX.Element>(<></>);
  const [iconObjetivo, setIconObjetivo] = useState<JSX.Element>(<></>);
  const [iconNumMoradores, setIconNumMoradores] = useState<JSX.Element>(<></>);

  const [checkNome, setCheckNome] = useState<boolean | null>(null);
  const [checkSexo, setCheckSexo] = useState<boolean | null>(null);
  const [checkEstadoCivil, setCheckEstadoCivil] = useState<boolean | null>(
    null,
  );
  const [checkCPF, setCheckCPF] = useState<boolean | null>(null);
  const [checkRG, setCheckRG] = useState<boolean | null>(null);
  const [checkOrgaoEmissor, setCheckOrgaoEmissor] = useState<boolean | null>(
    null,
  );
  const [checkTelefone, setCheckTelefone] = useState<boolean | null>(null);
  const [checkEmail, setCheckEmail] = useState<boolean | null>(null);
  const [checkDataNascimento, setCheckDataNascimento] = useState<
    boolean | null
  >(null);
  const [checkNacionalidade, setCheckNacionalidade] = useState<boolean | null>(
    null,
  );
  const [checkRendaApurada, setCheckRendaApurada] = useState<boolean | null>(
    null,
  );
  const [checkLogradouro, setCheckLogradouro] = useState<boolean | null>(null);
  const [checkNumero, setCheckNumero] = useState<boolean | null>(null);
  const [checkBairro, setCheckBairro] = useState<boolean | null>(null);
  const [checkCidade, setCheckCidade] = useState<boolean | null>(null);
  const [checkUf, setCheckUf] = useState<boolean | null>(null);
  const [checkCep, setCheckCep] = useState<boolean | null>(null);
  const [isModaGenericlOpen, setModaGenericOpen] = useState(false);
  const statustoShowPipeButton = [
    'Anexar Documentos',
    'Documentacao insuficiente',
    'Venda efetivada',
    'Em Análise',
    'Aprovado',
    'Devolutiva de Documentos',
  ];
  const statusToShowAprrovedButton = ['Aprovado', 'Devolutiva de Documentos'];

  const handleCloseModalGeneric = () => {
    setModaGenericOpen(false);
  };

  const generateCheckIcon = (state: boolean | null): JSX.Element => {
    if (state === true) {
      return <FaCheck style={{ color: 'green', marginLeft: '5px' }} />;
    } else if (state === false) {
      return <FaTimes style={{ color: 'red', marginLeft: '5px' }} />;
    } else {
      return <></>;
    }
  };

  const verifyInputItens = useCallback(async () => {
    let propostaId = location.state.rowData.id;

    try {
      const resultadoAnalise = await PropostasService.resultAnalysiChatGPT(
        propostaId,
      );
      if (resultadoAnalise.length > 0) {
        const analysisData: AnalysisData = resultadoAnalise[0];
        if (analysisData.nome !== undefined) {
          setCheckNome(analysisData.nome);
        }
        if (analysisData.sexo !== undefined) {
          setCheckSexo(analysisData.sexo);
        }
        if (analysisData.estado_civil !== undefined) {
          setCheckEstadoCivil(analysisData.estado_civil);
        }
        if (analysisData.cpf !== undefined) {
          setCheckCPF(analysisData.cpf);
        }
        if (analysisData.rg !== undefined) {
          setCheckRG(analysisData.rg);
        }
        if (analysisData.orgao_emissor !== undefined) {
          setCheckOrgaoEmissor(analysisData.orgao_emissor);
        }
        if (analysisData.telefone !== undefined) {
          setCheckTelefone(analysisData.telefone);
        }
        if (analysisData.email !== undefined) {
          setCheckEmail(analysisData.email);
        }
        if (analysisData.data_nascimento !== undefined) {
          setCheckDataNascimento(analysisData.data_nascimento);
        }
        if (analysisData.nacionalidade !== undefined) {
          setCheckNacionalidade(analysisData.nacionalidade);
        }
        if (analysisData.renda_apurada !== undefined) {
          setCheckRendaApurada(analysisData.renda_apurada);
        }
        if (analysisData.logradouro !== undefined) {
          setCheckLogradouro(analysisData.logradouro);
        }
        if (analysisData.numero !== undefined) {
          setCheckNumero(analysisData.numero);
        }
        if (analysisData.bairro !== undefined) {
          setCheckBairro(analysisData.bairro);
        }
        if (analysisData.cidade !== undefined) {
          setCheckCidade(analysisData.cidade);
        }
        if (analysisData.uf !== undefined) {
          setCheckUf(analysisData.uf);
        }
        if (analysisData.cep !== undefined) {
          setCheckCep(analysisData.cep);
        }
      }
    } catch (error) {
      console.error('Ocorreu um erro', error);
    }
  }, []);

  useEffect(() => {
    verifyInputItens();
  }, [verifyInputItens]);

  useEffect(() => {
    setIconNome(generateCheckIcon(checkNome));
    setIconEstadoCivil(generateCheckIcon(checkEstadoCivil));
    setIconCPF(generateCheckIcon(checkCPF));
    setIconRG(generateCheckIcon(checkRG));
    setIconOrgaoEmissor(generateCheckIcon(checkOrgaoEmissor));
    setIconTelefone(generateCheckIcon(checkTelefone));
    setIconEmail(generateCheckIcon(checkEmail));
    setIconDataNascimento(generateCheckIcon(checkDataNascimento));
    setIconNacionalidade(generateCheckIcon(checkNacionalidade));
    setIconRendaApurada(generateCheckIcon(checkRendaApurada));
    setIconLogradouro(generateCheckIcon(checkLogradouro));
    setIconNumero(generateCheckIcon(checkNumero));
    setIconBairro(generateCheckIcon(checkBairro));
    setIconCidade(generateCheckIcon(checkCidade));
    setIconUf(generateCheckIcon(checkUf));
    setIconCep(generateCheckIcon(checkCep));
  }, [
    checkNome,
    checkSexo,
    checkEstadoCivil,
    checkCPF,
    checkRG,
    checkOrgaoEmissor,
    checkTelefone,
    checkEmail,
    checkDataNascimento,
    checkNacionalidade,
    checkRendaApurada,
    checkLogradouro,
    checkNumero,
    checkBairro,
    checkCidade,
    checkUf,
    checkCep,
  ]);

  const getParcipantProposals = async () => {
    const participantProposals = await PropostasService.getProposalParticipants(
      id_participante,
    );
    setParticipantProposals(participantProposals.proposals_physical);
  };

  const getFinancialSeriesByID = async (id: string) => {
    const data = await PropostasService.getFinancialSeriesByID(id);
    setFinancialSeriesData(data.filter((resp: any) => !resp.c_removed));
  };

  useEffect(() => {
    getParcipantProposals();
    getFinancialSeriesByID(id);
  }, [id]);

  useEffect(() => {
    if (financialSeriesData.length > 0) {
      financialSeriesData.forEach((series: any) => {
        if (series.type.label === 'sinal') {
          setDataVencimentoSinal(series.data_inicio.split('T')[0]);
          setQuantidadeSinal(series.quantidade_parcelas);
          setValorEntrada(series.valor_parcela);
        }
        if (series.type.label === 'mensal_sem_juros') {
          setValorParcela(series.valor_parcela);
          setQuantidadeParcelas(series.quantidade_parcelas);
          setDataVencimentoParcelas(series.data_inicio.split('T')[0]);
        } else if (series.type.label === 'mensal_com_juros') {
          setValorParcela(series.valor_parcela);
          setQuantidadeParcelas(series.quantidade_parcelas);
          setDataVencimentoParcelas(series.data_inicio.split('T')[0]);
        }
        if (series.type.label === 'anual') {
          setTemBalaoAnual(true);
          setBaloesAnuais(prevState => {
            if (!prevState.some((balao: any) => balao.id === series.id)) {
              return [
                ...prevState,
                {
                  id: series.id,
                  data: series.data_inicio
                    .split('T')[0]
                    .split('-')
                    .reverse()
                    .join('/'),
                  quantidade: series.quantidade_parcelas,
                  valor: series.valor_parcela,
                },
              ];
            }
            return prevState;
          });
        }
        if (series.type.label === 'unica') {
          setBaloesUnicos((prevState: any) => {
            setTemBalaoUnico(true);
            if (!prevState.some((balao: any) => balao.id === series.id)) {
              return [
                ...prevState,
                {
                  id: series.id,
                  data: series.data_inicio
                    .split('T')[0]
                    .split('-')
                    .reverse()
                    .join('/'),
                  valor: series.valor_parcela,
                },
              ];
            }
            return prevState;
          });
        }
      });
    }
  }, [financialSeriesData, dataVencimentoParcelas]);

  const getSignedFiles = async (docs: any) => {
    const updatedFiles = await Promise.all(
      docs.map(async (file: any) => {
        const chave = file.document.split(
          'https://s3.us-east-1.amazonaws.com/bairru-corretores-v2/',
        )[1];
        try {
          const signedURL = await PropostasService.getFilesAWS([chave]);

          return {
            ...file,
            document: signedURL[0] || file.document,
          };
        } catch (error) {
          console.error(
            'Erro ao obter URL assinada para a chave:',
            chave,
            error,
          );
          return {
            ...file,
            document: null,
          };
        }
      }),
    );

    setSignedFiles(updatedFiles);

    return updatedFiles;
  };

  useEffect(() => {
    getParcipantProposals();
  }, []);

  useEffect(() => {
    if (documentos) {
      getSignedFiles(documentos);
    }
  }, [documentos]);

  return (
    <>
      <Content>
        <DetalhesContainer>
          <DetalhesHeader>
            <div className="titulos">
              <div className="top-header">
                <h1>Proposta completa</h1>
              </div>
              <div className="top-header" style={{ marginTop: 10 }}>
                <h2>
                  Status: <span>{statusValue}</span>
                </h2>
                <h2 style={{ marginLeft: 0 }}>
                  <CheckCircleOutlineIcon style={{ fill: 'lightgreen' }} />
                  Realizada em {formataData(criacao)}
                </h2>
                <h2>
                  <UpdateIcon style={{ fill: 'yellow' }} />
                  Última atualização em {formataData(atualizacao)}
                </h2>
              </div>
            </div>
          </DetalhesHeader>

          <DetalhesContent>
            <InputsContent>
              <div className="info-dados-app">
                <h2>Dados enviados na proposta pelo app</h2>
                <p>Alguns dados não pode ser alterados</p>
                <p>Proposta: {codMega}</p>
                <p>Land id: {landId}</p>
                <p>
                  {' '}
                  <h2>imobiliária:</h2> <strong>{imobiliaria}</strong>
                </p>
                <p>
                  <h2>Lote:</h2>
                </p>
                <p>Quadra: {lote?.quadra}</p>
                <p>Lote: {lote?.lote}</p>
                <h2>Empreendimento:</h2>
                <div className="empreendimento-label">
                  <p>{lote?.empreendimento}</p>
                  {empElegivelPagadoria && (
                    <p className="tag-empreendimento-pagadoria">PAGADORIA</p>
                  )}
                  {isBoletosPagadoriaGerados && (
                    <p className="tag-empreendimento-pagadoria tag-azul">
                      BOLETOS GERADOS
                    </p>
                  )}
                  {isBoletosPagadoriaLiberados && (
                    <p className="tag-empreendimento-pagadoria tag-verde">
                      BOLETOS LIBERADOS
                    </p>
                  )}
                </div>
                <h2>Corretor:</h2>
                <p> {nomeCorretor.toLocaleUpperCase()}</p>
                <h2>Telefone Corretor:</h2>
                <p> {telefoneCorretor}</p>

                <div className="dropdown-historico">
                  {dadosClienteResumo.length > 0 && (
                    <>
                      <button
                        type="button"
                        onClick={() => setMostrarHistorico(!mostrarHistorico)}
                        className={`historico-btn ${
                          mostrarHistorico ? 'ativo' : 'oculto'
                        }`}
                      >
                        {mostrarHistorico ? 'Ocultar' : 'Mostrar'} Histórico do{' '}
                        {nomeClienteSelecionado}
                      </button>
                      <div className="dropdown">
                        {mostrarHistorico && (
                          <ul>
                            {dadosClienteResumo.map(contrato => (
                              <li key={contrato.id_contrato}>
                                <p>Nome: {contrato.nome_cliente}</p>
                                <p>
                                  Data do contrato:{' '}
                                  {formatarData(contrato.data_contrato)}
                                </p>
                                <p>Id do contrato: {contrato.id_contrato}</p>
                                <p>Imobiliária: {contrato.imobiliaria}</p>
                                <p>
                                  Inadimplência:{' '}
                                  {!contrato.inadimplente
                                    ? 'Verdadeiro'
                                    : 'Falso'}
                                </p>
                                <p>
                                  Lote/Quadra: {contrato.lote} /{' '}
                                  {contrato.quadra}
                                </p>
                                <p>
                                  Nome empreendimento:{' '}
                                  {contrato.nome_empreendimento}
                                </p>
                                <p>
                                  Status do contrato: {contrato.status_contrato}
                                </p>
                                <p>Tier: {contrato.tier}</p>
                                <hr />
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </InputsContent>

            <InputsContent>
              <form>
                {values.map((value: any) => {
                  return (
                    <div key={value.index}>
                      <SessionContente>
                        <div className="info-dados-app">
                          <h2>
                            Dados pessoais{' '}
                            {value.tipo_participante === 'principal'
                              ? '(principal)'
                              : '(secundario)'}
                          </h2>
                        </div>
                      </SessionContente>
                      <InputsContainer>
                        <InputField
                          label="Nome"
                          name="nome"
                          value={value.nome}
                          width={400}
                          onChange={(e: any) => {
                            handleChange(e, value.index);
                          }}
                        />
                        {iconNome}
                      </InputsContainer>
                      <InputsContainer>
                        <InputField
                          label="Sexo"
                          name="sexo"
                          value={value.sexo}
                          width={300}
                          onChange={(e: any) => {
                            handleChange(e, value.index);
                          }}
                        />
                        {iconSexo}
                      </InputsContainer>
                      <InputsContainer>
                        <InputField
                          className={getInputClass(
                            validationModalIASuccess.estado_civil,
                          )}
                          label="Estado civil"
                          disabled
                          value={value.estado_civil}
                          width={200}
                        />
                        {validationModalIASuccessMSG.estado_civil !== '' && (
                          <div
                            style={{
                              display: 'flex',
                              marginLeft: 6,
                              fontSize: 14,
                              color: '#6b3fa0',
                              marginTop: '-4px',
                            }}
                          >
                            <AutoAwesomeIcon
                              style={{
                                height: 16,
                                justifyContent: 'center',
                                width: 16,
                                marginRight: 6,
                              }}
                            />
                            {validationModalIASuccessMSG.estado_civil}
                          </div>
                        )}
                        {iconEstadoCivil}
                      </InputsContainer>
                      <InputsContainer>
                        <InputField
                          className={getInputClass(
                            validationModalIASuccess.cpf,
                          )}
                          label="CPF"
                          value={value.cpf}
                          disabled
                          width={200}
                        />
                        {validationModalIASuccess.cpf === 'sucess' && (
                          <div
                            style={{
                              display: 'flex',
                              marginLeft: 6,
                              fontSize: 14,
                              color: '#6b3fa0',
                              marginTop: '-4px',
                            }}
                          >
                            <AutoAwesomeIcon
                              style={{
                                height: 16,
                                justifyContent: 'center',
                                width: 16,
                                marginRight: 6,
                              }}
                            />
                            {validationModalIASuccessMSG.cpf}
                          </div>
                        )}
                        {iconCPF}
                      </InputsContainer>
                      <InputsContainer>
                        <InputField
                          label="RG"
                          name="rg"
                          value={value.rg}
                          className={getInputClass(validationModalIASuccess.rg)}
                          width={300}
                          onChange={(e: any) => {
                            handleChange(e, value.index);
                          }}
                        />
                        {validationModalIASuccess.rg === 'sucess' && (
                          <div
                            style={{
                              display: 'flex',
                              marginLeft: 6,
                              fontSize: 14,
                              color: '#6b3fa0',
                              marginTop: '-4px',
                            }}
                          >
                            <AutoAwesomeIcon
                              style={{
                                height: 16,
                                justifyContent: 'center',
                                width: 16,
                                marginRight: 6,
                              }}
                            />
                            {validationModalIASuccessMSG.rg}
                          </div>
                        )}
                        {iconRG}
                      </InputsContainer>

                      <InputsContainer>
                        <InputField
                          label="Orgão emissor"
                          name="orgao_rg"
                          value={value.orgao_rg}
                          className={`${classes.textField} ${
                            validationModalIASuccess.orgao_rg
                              ? classes.successInput
                              : ''
                          }`}
                          width={150}
                          onChange={(e: any) => {
                            handleChange(e, value.index);
                          }}
                        />
                        {validationModalIASuccess.orgao_rg === 'sucess' && (
                          <div
                            style={{
                              display: 'flex',
                              marginLeft: 6,
                              fontSize: 14,
                              color: '#6b3fa0',
                              marginTop: '-4px',
                            }}
                          >
                            <AutoAwesomeIcon
                              style={{
                                height: 16,
                                justifyContent: 'center',
                                width: 16,
                                marginRight: 6,
                              }}
                            />
                            {validationModalIASuccessMSG.orgao_emissor}
                          </div>
                        )}
                        {iconOrgaoEmissor}
                      </InputsContainer>
                      <InputsContainer>
                        <InputField
                          label="Telefone"
                          name="telefone"
                          value={value.telefone}
                          onChange={(e: any) => {
                            handleChange(e, value.index);
                          }}
                          width={200}
                        />
                        {iconTelefone}
                      </InputsContainer>

                      <InputsContainer>
                        <InputField
                          label="E-mail"
                          name="email"
                          value={value.email}
                          onChange={(e: any) => {
                            handleChange(e, value.index);
                          }}
                          width={400}
                        />
                        {iconEmail}
                      </InputsContainer>

                      <InputsContainer>
                        <InputField
                          // className="inputs"
                          className={getInputClass(
                            validationModalIASuccess.nascimento,
                          )}
                          label="Data de nascimento"
                          disabled
                          value={value.data_nascimento
                            .split('T')[0]
                            .split('-')
                            .reverse()
                            .join('/')}
                          width={200}
                        />
                        {validationModalIASuccess.cpf === 'sucess' && (
                          <div
                            style={{
                              display: 'flex',
                              marginLeft: 6,
                              fontSize: 14,
                              color: '#6b3fa0',
                              marginTop: '-4px',
                            }}
                          >
                            <AutoAwesomeIcon
                              style={{
                                height: 16,
                                justifyContent: 'center',
                                width: 16,
                                marginRight: 6,
                              }}
                            />
                            {validationModalIASuccessMSG.nascimento}
                          </div>
                        )}
                        {iconDataNascimento}
                      </InputsContainer>
                      <InputsContainer>
                        <InputField
                          label="Nacionalidade"
                          name="nacionalidade"
                          value={value.nacionalidade}
                          onChange={(e: any) => {
                            handleChange(e, value.index);
                          }}
                          width={200}
                        />
                        {iconNacionalidade}
                      </InputsContainer>

                      <SessionContente>
                        <div className="info-dados-app">
                          <h2>
                            Trabalho{' '}
                            {value.tipo_participante === 'principal' &&
                              'e Escolaridade'}
                          </h2>
                        </div>
                      </SessionContente>

                      <InputsContainer>
                        <InputField
                          className="inputs"
                          label="Cargo"
                          disabled
                          value={value.cargo}
                          width={300}
                        />
                        {iconCargo}
                      </InputsContainer>
                      {value.tipo_participante === 'principal' && (
                        <>
                          <InputsContainer>
                            <InputField
                              className="inputs"
                              label="CLT"
                              disabled
                              value={clt}
                              width={300}
                            />
                            {iconCLT}
                          </InputsContainer>
                          <InputsContainer>
                            <InputField
                              className="inputs"
                              label="Escolaridade"
                              disabled
                              value={escolaridade}
                              width={300}
                            />
                            {iconEscolaridade}
                          </InputsContainer>
                          <SessionContente>
                            <div className="info-dados-app">
                              <h2>Família</h2>
                            </div>
                          </SessionContente>
                          <InputsContainer>
                            <InputField
                              className="inputs"
                              label="Moradia atual"
                              value={moradiaAtual}
                              disabled
                              width={300}
                            />
                            {iconMoradiaAtual}
                          </InputsContainer>
                          <InputsContainer>
                            <InputField
                              className="inputs"
                              label="Renda familiar"
                              value={formatCurrency(rendaFamiliar)}
                              disabled
                              width={200}
                            />
                            {iconRendaApurada}
                          </InputsContainer>
                          <InputsContainer>
                            <InputField
                              className="inputs"
                              label="Dependentes"
                              value={dependentes}
                              disabled
                              width={150}
                            />
                            {iconDependentes}
                          </InputsContainer>
                          <InputsContainer>
                            <InputField
                              className="inputs"
                              label="Objetivo"
                              disabled
                              value={objetivo}
                              width={200}
                            />
                            {iconObjetivo}
                          </InputsContainer>
                          <InputsContainer>
                            <InputField
                              className="inputs"
                              label="Nº moradores"
                              value={numMoradores}
                              disabled
                              width={150}
                            />
                            {iconNumMoradores}
                          </InputsContainer>

                          <SessionContente>
                            <div className="info-dados-app">
                              <h2>Endereço Principal</h2>
                            </div>
                          </SessionContente>
                          <InputsContainer>
                            <InputField
                              label="CEP"
                              className={getInputClass(
                                validationModalIASuccess.cep,
                              )}
                              name="cep"
                              value={value.cep}
                              onChange={(e: any) => {
                                handleChange(e, value.index);
                              }}
                              width={200}
                            />
                            {validationModalIASuccessMSG.cpf !== '' && (
                              <div
                                style={{
                                  display: 'flex',
                                  marginLeft: 6,
                                  fontSize: 14,
                                  color: '#6b3fa0',
                                  marginTop: '-4px',
                                }}
                              >
                                <AutoAwesomeIcon
                                  style={{
                                    height: 16,
                                    justifyContent: 'center',
                                    width: 16,
                                    marginRight: 6,
                                  }}
                                />
                                {validationModalIASuccessMSG.cep}
                              </div>
                            )}
                            {iconCep}
                          </InputsContainer>
                          <InputsContainer>
                            <InputField
                              label="UF"
                              name="uf"
                              className={getInputClass(
                                validationModalIASuccess.uf,
                              )}
                              value={value.uf}
                              onChange={(e: any) => {
                                handleChange(e, value.index);
                              }}
                              width={150}
                            />
                            {validationModalIASuccessMSG.uf !== '' && (
                              <div
                                style={{
                                  display: 'flex',
                                  marginLeft: 6,
                                  fontSize: 14,
                                  color: '#6b3fa0',
                                  marginTop: '-4px',
                                }}
                              >
                                <AutoAwesomeIcon
                                  style={{
                                    height: 16,
                                    justifyContent: 'center',
                                    width: 16,
                                    marginRight: 6,
                                  }}
                                />
                                {validationModalIASuccessMSG.uf}
                              </div>
                            )}
                            {iconUf}
                          </InputsContainer>
                          <InputsContainer>
                            <InputField
                              label="Municipio"
                              name="municipio"
                              className={getInputClass(
                                validationModalIASuccess.cidade,
                              )}
                              value={value.municipio}
                              onChange={(e: any) => {
                                handleChange(e, value.index);
                              }}
                              width={300}
                            />
                            {validationModalIASuccessMSG.cidade !== '' && (
                              <div
                                style={{
                                  display: 'flex',
                                  marginLeft: 6,
                                  fontSize: 14,
                                  color: '#6b3fa0',
                                  marginTop: '-4px',
                                }}
                              >
                                <AutoAwesomeIcon
                                  style={{
                                    height: 16,
                                    justifyContent: 'center',
                                    width: 16,
                                    marginRight: 6,
                                  }}
                                />
                                {validationModalIASuccessMSG.cidade}
                              </div>
                            )}
                            {iconCidade}
                          </InputsContainer>
                          <InputsContainer>
                            <InputField
                              label="Bairro"
                              name="bairro"
                              className={getInputClass(
                                validationModalIASuccess.bairro,
                              )}
                              value={value.bairro}
                              onChange={(e: any) => {
                                handleChange(e, value.index);
                              }}
                              width={300}
                            />
                            {validationModalIASuccessMSG.bairro !== '' && (
                              <div
                                style={{
                                  display: 'flex',
                                  marginLeft: 6,
                                  fontSize: 14,
                                  color: '#6b3fa0',
                                  marginTop: '-4px',
                                }}
                              >
                                <AutoAwesomeIcon
                                  style={{
                                    height: 16,
                                    justifyContent: 'center',
                                    width: 16,
                                    marginRight: 6,
                                  }}
                                />
                                {validationModalIASuccessMSG.bairro}
                              </div>
                            )}
                            {iconBairro}
                          </InputsContainer>
                          <InputsContainer>
                            <InputField
                              label="Logradouro"
                              name="logradouro"
                              className={getInputClass(
                                validationModalIASuccess.logradouro,
                              )}
                              value={value.logradouro}
                              onChange={(e: any) => {
                                handleChange(e, value.index);
                              }}
                              width={300}
                            />
                            {validationModalIASuccessMSG.logradouro !== '' && (
                              <div
                                style={{
                                  display: 'flex',
                                  marginLeft: 6,
                                  fontSize: 14,
                                  color: '#6b3fa0',
                                  marginTop: '-4px',
                                }}
                              >
                                <AutoAwesomeIcon
                                  style={{
                                    height: 16,
                                    justifyContent: 'center',
                                    width: 16,
                                    marginRight: 6,
                                  }}
                                />
                                {validationModalIASuccessMSG.logradouro}
                              </div>
                            )}
                            {iconLogradouro}
                          </InputsContainer>
                          <InputsContainer>
                            <InputField
                              label="Numero"
                              name="numero"
                              className={getInputClass(
                                validationModalIASuccess.numero,
                              )}
                              value={value.numero}
                              onChange={(e: any) => {
                                handleChange(e, value.index);
                              }}
                              width={150}
                            />
                            {validationModalIASuccessMSG.numero !== '' && (
                              <div
                                style={{
                                  display: 'flex',
                                  marginLeft: 6,
                                  fontSize: 14,
                                  color: '#6b3fa0',
                                  marginTop: '-4px',
                                }}
                              >
                                <AutoAwesomeIcon
                                  style={{
                                    height: 16,
                                    justifyContent: 'center',
                                    width: 16,
                                    marginRight: 6,
                                  }}
                                />
                                {validationModalIASuccessMSG.numero}
                              </div>
                            )}
                            {iconNumero}
                          </InputsContainer>

                          <InputsContainer>
                            <InputField
                              label="Complemento"
                              name="complemento"
                              className={getInputClass(
                                validationModalIASuccess.complemento,
                              )}
                              value={value.complemento}
                              onChange={(e: any) => {
                                handleChange(e, value.index);
                              }}
                              width={300}
                            />
                            {validationModalIASuccessMSG.complemento !== '' && (
                              <div
                                style={{
                                  display: 'flex',
                                  marginLeft: 6,
                                  fontSize: 14,
                                  color: '#6b3fa0',
                                  marginTop: '-4px',
                                }}
                              >
                                <AutoAwesomeIcon
                                  style={{
                                    height: 16,
                                    justifyContent: 'center',
                                    width: 16,
                                    marginRight: 6,
                                  }}
                                />
                                {validationModalIASuccessMSG.complemento}
                              </div>
                            )}
                          </InputsContainer>
                        </>
                      )}
                    </div>
                  );
                })}
              </form>

              <SessionContente>
                <div className="info-dados-app">
                  <h2>Valores</h2>
                </div>
              </SessionContente>
              <InputsContainer>
                <InputField
                  className="inputs"
                  label="Valor do lote"
                  disabled
                  value={formatCurrency(valor_total)}
                  width={140}
                />
              </InputsContainer>
              <SessionContente />
              <InputsContainer>
                <InputField
                  className="inputs"
                  label="Qtd. sinal"
                  disabled={!empElegivelPagadoria}
                  value={quantidadeSinal}
                  onChange={(e: any) => {
                    setQuantidadeSinal(e.target.value);
                  }}
                  width={90}
                />
              </InputsContainer>
              <InputsContainer>
                <InputField
                  className="inputs"
                  label="Valor sinal"
                  disabled
                  value={formatCurrency(valorEntrada)}
                  width={140}
                />
              </InputsContainer>
              <SessionContente />
              <InputsContainer>
                <InputField
                  className="inputs"
                  label="Qtd. parcelas"
                  disabled
                  value={num_parcelas}
                  width={110}
                />
              </InputsContainer>
              <InputsContainer>
                <InputField
                  className="inputs"
                  label="Valor parcelas"
                  disabled
                  value={formatCurrency(valorParcela)}
                  width={140}
                />
              </InputsContainer>
              {temBalaoAnual && (
                <>
                  <SessionContente>
                    <div className="info-dados-app">
                      <h2 style={{ marginBottom: '5%' }}>Balão Anual</h2>
                    </div>
                  </SessionContente>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {baloesAnuais.map((balao, index) => (
                      <div
                        style={{
                          display: 'flex',
                          marginLeft: '0.7%',
                          flexDirection: 'row',
                        }}
                        key={index}
                      >
                        <div
                          style={{
                            display: 'flex',
                            gap: 15,
                            marginBottom: 8,
                          }}
                        >
                          <InputField
                            className="inputs"
                            label="Data Balão Anual"
                            value={balao.data}
                            disabled={true}
                            width={140}
                          />
                          <InputField
                            className="inputs"
                            label="Qtd Anual"
                            disabled={true}
                            value={balao.quantidade}
                            width={90}
                          />
                          <InputField
                            className="inputs"
                            label="Valor Balão Anual"
                            disabled={true}
                            value={formatCurrency(balao.valor)}
                            width={140}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
              {temBalaoUnico && (
                <>
                  <SessionContente>
                    <div className="info-dados-app">
                      <h2 style={{ marginBottom: '5%' }}>Balão Único</h2>
                    </div>
                  </SessionContente>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {baloesUnicos.map((balao, index) => (
                      <div
                        style={{
                          display: 'flex',
                          marginLeft: '0.7%',
                          flexDirection: 'row',
                        }}
                        key={index}
                      >
                        <div
                          style={{
                            display: 'flex',
                            gap: 15,
                            marginBottom: 8,
                          }}
                        >
                          <InputField
                            className="inputs"
                            label="Data  Balão Único"
                            value={balao.data}
                            disabled={true}
                            width={140}
                          />
                          <InputField
                            className="inputs"
                            label="Valor Balão Único"
                            disabled={true}
                            value={formatCurrency(balao.valor)}
                            width={140}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
              {valor_reduzidas !== '0' && (
                <InputsContainer>
                  <InputField
                    className="inputs"
                    label="Valor reduzidas"
                    disabled
                    value={valor_reduzidas}
                    width={140}
                  />
                </InputsContainer>
              )}
              <SessionContente>
                <div className="info-dados-app">
                  <h2>Datas</h2>
                </div>
              </SessionContente>

              <InputsContainer>
                <InputField
                  className="inputs"
                  label="Data sinal"
                  value={dataVencimentoSinal
                    ?.split('T')[0]
                    .split('-')
                    .reverse()
                    .join('/')}
                  onChange={handleChangeDataVencimentosSinal}
                  disabled={!empElegivelPagadoria}
                  width={140}
                />
              </InputsContainer>
              <InputsContainer>
                <InputField
                  className="inputs"
                  label="Data parcela"
                  value={dataVencimentoParcelas
                    ?.split('T')[0]
                    .split('-')
                    .reverse()
                    .join('/')}
                  disabled
                  width={140}
                />
              </InputsContainer>
              {data_vencimento_reduzidas?.split('T')[0] !== '1970-01-01' && (
                <InputsContainer>
                  <InputField
                    className="inputs"
                    label="Data reduzidas"
                    disabled
                    value={data_vencimento_reduzidas
                      ?.split('T')[0]
                      .split('-')
                      .reverse()
                      .join('/')}
                    width={140}
                  />
                </InputsContainer>
              )}
              <SessionContente>
                <Botao tipo="adicionar" tamanho="medio" onClick={handleSave}>
                  Atualizar dados
                </Botao>
              </SessionContente>
            </InputsContent>
            {signedFiles ? <PessoaFisica filesUrl={signedFiles} /> : <></>}
            <DocumentsSendContrato
              updateUploadedFiles={updateUploadedFiles}
              participant_id={id_participante}
              name={'Devolução contrato'}
              files={{
                files: signedFiles,
              }}
            />

            <DocumentsSendBoleto
              updateUploadedFiles={updateUploadedFiles}
              participant_id={id_participante}
              name={'Devolução boleto'}
              files={{
                files: signedFiles,
              }}
            />

            <SessionContente>
              <Botao
                tipo="salvar"
                tamanho="medio"
                onClick={() => {
                  salvarProposta();
                  changeStatus('Devolutiva de Documentos', codMega, observacao);
                }}
              >
                Enviar Documentos
              </Botao>
              <Botao
                tipo="falta"
                tamanho="medio"
                onClick={() => {
                  handleSave();
                  setIsfaltaDocumentos(true);
                }}
              >
                Falta de documentos
              </Botao>
            </SessionContente>
            <InputsContent className="descricao-container-inputs">
              <InputsContainer className="descricao-container">
                <h2>Observações</h2>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  multiline
                  maxRows="8"
                  name="answer"
                  type="text"
                  value={observacao}
                  onChange={e => {
                    setObservacao(e.target.value);
                  }}
                />
              </InputsContainer>
              <SessionContente>
                <Botao
                  tipo="salvar"
                  tamanho="medio"
                  onClick={() => {
                    handleSave();
                    setIsApproved(true);
                  }}
                >
                  Aprovar
                </Botao>
                <Botao
                  tipo="voltar"
                  tamanho="medio"
                  onClick={() => {
                    handleSave();
                    setIsDisapproved(true);
                  }}
                >
                  inconsistência
                </Botao>
                <Botao
                  tipo="excluir"
                  tamanho="medio"
                  onClick={() => {
                    setIsCancelModalOpen(true);
                  }}
                >
                  Cancelar
                </Botao>
              </SessionContente>
            </InputsContent>
          </DetalhesContent>
        </DetalhesContainer>
        <ApprovedModal
          isModalLoading={isApprovedModalLoading}
          isModalOpen={isApproved}
          onCloseModal={() => setIsApproved(false)}
          onApprovedClick={() => {
            handleUpdateVimob(values, empCod);
            mudaSituacao(dataCV[0]?.id_reserva, 30);
            analysisResult({
              proposal_id: location.state.rowData.id,
              proposal_valid: true,
              comentary: observacao,
            });
          }}
          showProposalInfo={empElegivelPagadoria}
          proposalInfo={{
            imobiliaria,
            nomeCorretor: nomeCorretor.toLocaleUpperCase(),
            nomeCliente: Object(
              values.find(part => part.tipo_participante === 'principal'),
            ).nome,
            valorSinal: formatCurrency(valor_entrada),
            quantidadeSinal: quantidadeSinal,
            dataSinal: dataVencimentoSinal
              ?.split('T')[0]
              .split('-')
              .reverse()
              .join('/'),
          }}
        />
        <DisapprovedModal
          isModalOpen={isDisapproved}
          onCloseModal={() => setIsDisapproved(false)}
          onDisapprovedClick={() => {
            mudaSituacao(dataCV[0]?.id_reserva, 29);
            analysisResult({
              proposal_id: location.state.rowData.id,
              proposal_valid: false,
              comentary: observacao,
            });
            setIsDisapproved(false);
          }}
        />
        <DisapprovedModal
          isModalOpen={isfaltaDocumentos}
          onCloseModal={() => setIsfaltaDocumentos(false)}
          onDisapprovedClick={() => {
            changeStatus('Documentacao insuficiente', codMega, observacao);
            mudaSituacao(dataCV[0]?.id_reserva, 28);
            setIsfaltaDocumentos(false);
          }}
        />

        <CancelIfInputModal
          isModalOpen={isCancelModalOpen}
          onCancelClick={() => handleCancelClick(selectedReason)}
          onCloseModal={() => setIsCancelModalOpen(false)}
          onReasonChange={handleReasonChange}
        />
        <VencerModal
          isModalOpen={vencerModal}
          onCloseModal={() => setVencerModal(false)}
          onVencerClick={() => {
            changeStatus('Vencida', codMega);
            setVencerModal(false);
          }}
        />
      </Content>
      <Snackbars
        type={snackbarProps.type}
        handleClose={handleSnackbarClose}
        open={snackbarOpen}
      >
        {snackbarProps.message}
      </Snackbars>
    </>
  );
};

export default EditarProposta;
