/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */

import { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  FormControlLabel,
} from '@material-ui/core';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import { InputsContainer, InputsContent } from '../EditarProposta/styles';
import FileUpload from '../../../components/FileUpload';
import cores from '../../../styles/cores';
import Botao from '../../../components/Botao/Botao';
import { ContainerBotao } from './styles';

const PessoaFisica = (props: any) => {
  const { files, updateUploadedFiles, estadoCivil, participant_id } = props;
  const [checksLabel, setChecksLabel] = useState<any[]>([]);
  const [state, setState] = useState({
    checkedDocIDFoto: false,
    checkedDocCpf: false,
    checkedDocRes: false,
    checkedAssalariado: false,
    checkedPensionista: false,
    checkedNaoClt: false,
    checkedSolteiroA: false,
    checkedCasadoA: false,
    checkedCasadoB: false,
    checkedDivorciadoA: false,
    checkedDivorciadoB: false,
    checkedViuvoA: false,
    checkedViuvoB: false,
    checkedUniaoEstavelA: false,
    checkedUniaoEstavelB: false,
  });
  const deletDocs = (id: string) => {
    console.log('delete', id);
  };

  useEffect(() => {
    let aux_checksLabel: any = [];

    switch (estadoCivil) {
      case 'Solteiro':
        aux_checksLabel = [
          {
            label: 'Certidão de nascimento',
            variableLabel: 'checkedSolteiroA',
            variableValue: state.checkedSolteiroA,
          },
        ];
        setChecksLabel(aux_checksLabel);
        break;
      case 'Casado':
        aux_checksLabel = [
          {
            label: 'Certidão de casamento',
            variableLabel: 'checkedCasadoA',
            variableValue: state.checkedCasadoA,
          },
          {
            label: 'Pacto antenupcial',
            variableLabel: 'checkedCasadoB',
            variableValue: state.checkedCasadoB,
          },
        ];
        setChecksLabel(aux_checksLabel);
        break;
      case 'Divorciado':
        aux_checksLabel = [
          {
            label: 'Certidão de casamento com averbação de divórcio',
            variableLabel: 'checkedDivorciadoA',
            variableValue: state.checkedDivorciadoA,
          },
        ];
        setChecksLabel(aux_checksLabel);
        break;
      case 'Viúvo':
        aux_checksLabel = [
          {
            label: 'Comprovante de casamento (Certidão de casamento)',
            variableLabel: 'checkedViuvoA',
            variableValue: state.checkedViuvoA,
          },
          {
            label: 'Certidão de óbito',
            variableLabel: 'checkedViuvoB',
            variableValue: state.checkedViuvoB,
          },
        ];
        setChecksLabel(aux_checksLabel);
        break;
      case 'União Estável':
        aux_checksLabel = [
          {
            label: 'Comprovante de união estável (Declaração de união estável)',
            variableLabel: 'checkedUniaoEstavelA',
            variableValue: state.checkedUniaoEstavelA,
          },
          {
            label: 'Certidão de estado civil',
            variableLabel: 'checkedUniaoEstavelB',
            variableValue: state.checkedUniaoEstavelB,
          },
        ];
        setChecksLabel(aux_checksLabel);
        break;
      default:
        aux_checksLabel = [
          {
            label: 'Certidão de nascimento',
            variableLabel: 'checkedSolteiroA',
            variableValue: state.checkedSolteiroA,
          },
        ];
        setChecksLabel(aux_checksLabel);
    }
  }, [state]);

  const GreenCheckbox = withStyles({
    root: {
      color: cores.purple_1,
      '&$checked': {
        color: cores.purple_1,
      },
    },
    checked: {},
  })((props: CheckboxProps) => <Checkbox color="default" {...props} />);

  const handleChange = (event: { target: { name: any; checked: any } }) => {
    if (event.target.name === 'checkedAssalariado') {
      console.log('checkedAssalariado');
      state.checkedAssalariado = event.target.checked;
      state.checkedPensionista = false;
      state.checkedNaoClt = false;
      setState({ ...state, [event.target.name]: event.target.checked });
      console.log(state);
    } else if (event.target.name === 'checkedPensionista') {
      console.log('checkedPensionista');
      state.checkedAssalariado = false;
      state.checkedPensionista = event.target.checked;
      state.checkedNaoClt = false;
      setState({ ...state, [event.target.name]: event.target.checked });
      console.log(state);
    } else if (event.target.name === 'checkedNaoClt') {
      console.log('checkedNaoClt');
      state.checkedAssalariado = false;
      state.checkedPensionista = false;
      state.checkedNaoClt = event.target.checked;
      setState({ ...state, [event.target.name]: event.target.checked });
      console.log(state);
    } else {
      setState({ ...state, [event.target.name]: event.target.checked });
    }
  };

  const checkedAnexos = (arr: number) => {
    // console.log('arr esse', arr);
    if (arr >= 1 && arr <= 15) {
      return <DoneIcon className="checked" />;
    }
    if (arr > 15) {
      return (
        <>
          <ClearIcon className="wrong" />
          <p className="anexos-mensagem">* Anexar 15 arquivos no máximo</p>
        </>
      );
    }
    return (
      <>
        <ClearIcon className="wrong" />
        <p className="anexos-mensagem">* Documento Obrigatório</p>
      </>
    );
  };

  return (
    <>
      <InputsContent className="acordion-1">
        <Accordion className="acordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="acordion-summary"
          >
            <Typography className="anexar-documentos">
              <AttachFileIcon />
              Carta Proposta (CP) e Documentos Pessoais (RG ou CNH não vencida e
              CPF, frente e verso)
              {checkedAnexos(files.docPessoal)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <InputsContainer className="upload-fotos">
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <p>Marque aqui os documentos que você já anexou</p>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <FormControlLabel
                    control={
                      <GreenCheckbox
                        checked={state.checkedDocIDFoto}
                        onChange={handleChange}
                        name="checkedDocIDFoto"
                      />
                    }
                    label="CNH/RG"
                  />
                  <FormControlLabel
                    control={
                      <GreenCheckbox
                        checked={state.checkedDocCpf}
                        onChange={handleChange}
                        name="checkedDocCpf"
                      />
                    }
                    label="CPF"
                  />
                </div>
              </div>
              <FileUpload
                accept=".jpg,.png,.jpeg,.pdf,.docx,.doc"
                label=""
                multiple
                updateFilesCb={(e: any) => {
                  updateUploadedFiles(e, 'documentos pessoais', participant_id);
                }}
              />
              {files.files.map((file: any) => {
                if (file.tag === 'personal') {
                  return (
                    <ContainerBotao>
                      <Botao
                        tipo=""
                        tamanho="pequeno"
                        className="btn-salvar"
                        onClick={() => {
                          window.open(file.document.signedUrl, '_blank');
                        }}
                      >
                        {file.document.signedUrl}
                      </Botao>
                      {/* <Botao
                        tipo="excluir"
                        tamanho='pequeno'
                        className="btn-salvar"
                        onClick={() => { deletDocs(file.id) }}
                      >
                        Excluir
                      </Botao> */}
                    </ContainerBotao>
                  );
                } else return null;
              })}
            </InputsContainer>
          </AccordionDetails>
        </Accordion>
      </InputsContent>
      <InputsContent className="acordion-1">
        <Accordion className="acordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="acordion-summary"
          >
            <Typography className="anexar-documentos">
              <AttachFileIcon />
              {/* Comprovante de nascimento{' '} */}

              {estadoCivil === 'Solteiro'
                ? ' Comprovante de estado civil (Certidão de nascimento)'
                : ''}
              {estadoCivil === 'Casado'
                ? ' Comprovante de estado civil (Certidão de casamento e/ou Pacto antenupcial)'
                : ''}
              {estadoCivil === 'União Estável'
                ? 'Comprovante de estado civil (Declaração de união estável ou Certidão de estado civil)'
                : ''}
              {estadoCivil === 'Divorciado'
                ? 'Comprovante de estado civil (Certidão de casamento e Averbação do divórcio)'
                : ''}
              {estadoCivil === 'Viúvo'
                ? 'Comprovante de estado civil (Certidão de casamento e Certidão de óbito)'
                : ''}
              {estadoCivil === ''
                ? ' Comprovante de estado civil (Certidão de nascimento)'
                : ''}
              {/* {geraInput()} */}

              {checkedAnexos(files.certidaoEstadoCivil)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <InputsContainer className="upload-fotos">
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <p>Marque aqui os documentos que você já anexou</p>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  {checksLabel ? (
                    checksLabel.map((item: any) => {
                      return (
                        <>
                          <FormControlLabel
                            control={
                              <GreenCheckbox
                                checked={item.variableValue}
                                onChange={handleChange}
                                name={item.variableLabel}
                              />
                            }
                            label={item.label}
                          />
                        </>
                      );
                    })
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <FileUpload
                accept=".jpg,.png,.jpeg,.pdf,.docx,.doc"
                label=""
                multiple
                updateFilesCb={(e: any) => {
                  updateUploadedFiles(
                    e,
                    'certidao estado civil',
                    participant_id,
                  );
                }}
              />
              {files.files.map((file: any) => {
                if (file.tag === 'estado_civil') {
                  return (
                    <ContainerBotao>
                      <Botao
                        tipo=""
                        tamanho="pequeno"
                        className="btn-salvar"
                        onClick={() => {
                          window.open(file.document.signedUrl, '_blank');
                        }}
                      >
                        {file.document.signedUrl}
                      </Botao>
                      {/* <Botao
                        tipo="excluir"
                        tamanho='pequeno'
                        className="btn-salvar"
                        onClick={() => {childToParent()} }
                      >
                        Excluir
                      </Botao> */}
                    </ContainerBotao>
                  );
                } else return null;
              })}
            </InputsContainer>
          </AccordionDetails>
        </Accordion>
      </InputsContent>

      <InputsContent className="acordion-1">
        <Accordion className="acordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="acordion-summary"
          >
            <Typography className="anexar-documentos">
              <AttachFileIcon />
              Comprovante de residência (Até 90 dias)
              {checkedAnexos(files.comprovanteResidencia)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <InputsContainer className="upload-fotos">
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <p>Marque aqui os documentos que você já anexou</p>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <FormControlLabel
                    control={
                      <GreenCheckbox
                        checked={state.checkedDocRes}
                        onChange={handleChange}
                        name="checkedDocRes"
                      />
                    }
                    label="Comprovante de residência"
                  />
                </div>
                <p>
                  Exemplo de documentos aceitos : Conta de água,Conta de
                  luz,Conta de telefone fixo,Conta de gás,Conta de TV a cabo
                </p>
              </div>

              <FileUpload
                accept=".jpg,.png,.jpeg,.pdf,.docx,.doc"
                label=""
                multiple
                updateFilesCb={(e: any) => {
                  updateUploadedFiles(
                    e,
                    'comprovante de residencia',
                    participant_id,
                  );
                }}
              />
              {files.files.map((file: any) => {
                if (file.tag === 'comp_residencial') {
                  return (
                    <>
                      <ContainerBotao>
                        <Botao
                          tipo=""
                          tamanho="pequeno"
                          className="btn-salvar"
                          onClick={() => {
                            window.open(file.document.signedUrl, '_blank');
                          }}
                        >
                          {file.document.signedUrl}
                        </Botao>
                        {/* <Botao
                          tipo="excluir"
                          tamanho='pequeno'
                          className="btn-salvar"
                          onClick={() => { deletDocs(file.id) }}
                        >
                          Excluir
                        </Botao> */}
                      </ContainerBotao>
                    </>
                  );
                } else return null;
              })}
            </InputsContainer>
          </AccordionDetails>
        </Accordion>
      </InputsContent>

      {/* {mapDocumentos.map((inputDoc: any, index: any) => {
        if (mapDocumentos.length === 1) {
          return (
            <div>
              <h1>Teste</h1>
            </div>
          );
        } else {
          return console.log('Tamanho do array menor que 1');
        }
      })} */}

      <InputsContent className="acordion-1">
        <Accordion className="acordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="acordion-summary"
          >
            <Typography className="anexar-documentos">
              <AttachFileIcon />
              Comprovante de renda
              {checkedAnexos(files.comp_renda)}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <InputsContainer className="upload-fotos">
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <p>Selecione uma das opções abaixo</p>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <>
                    <FormControlLabel
                      control={
                        <GreenCheckbox
                          checked={state.checkedAssalariado}
                          onChange={handleChange}
                          name="checkedAssalariado"
                        />
                      }
                      label="Assalariado "
                    />
                    <FormControlLabel
                      control={
                        <GreenCheckbox
                          checked={state.checkedPensionista}
                          onChange={handleChange}
                          name="checkedPensionista"
                        />
                      }
                      label="Aposentado ou pensionista"
                    />
                  </>
                  <>
                    <FormControlLabel
                      control={
                        <GreenCheckbox
                          checked={state.checkedNaoClt}
                          onChange={handleChange}
                          name="checkedNaoClt"
                        />
                      }
                      label="Autônomos, Profissionais Liberais e Sócio de Empresas"
                    />
                  </>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <h5>Exemplo de documentos aceitos: </h5>
                  {state.checkedAssalariado ? (
                    <>
                      <p>
                        Holerite/Contracheque dos últimos 3 meses. Caso seja
                        funcionário público, basta solicitar o último; ou a
                        Declaração de Imposto de Renda; ou Extrato Bancário (90
                        dias);
                      </p>
                    </>
                  ) : state.checkedPensionista ? (
                    <>
                      <p style={{ marginLeft: 10, maxWidth: 750 }}>
                        Holerite/Contracheque dos últimos 3 meses. Caso seja
                        funcionário público, basta solicitar o último; ou a
                        Declaração de Imposto de Renda;
                      </p>
                    </>
                  ) : (
                    <>
                      <p>
                        Declaração de Imposto de Renda; ou Contrato ou
                        declaração de prestação de serviço; ou DECORE – assinado
                        pelo contador com prazo máximo e 3 meses; ou Extratos
                        bancários (90 dias).
                      </p>
                    </>
                  )}
                </div>
              </div>
              <FileUpload
                accept=".jpg,.png,.jpeg,.pdf,.docx,.doc"
                label=""
                multiple
                updateFilesCb={(e: any) => {
                  updateUploadedFiles(e, 'comp_renda', participant_id);
                }}
              />
              {files.files.map((file: any) => {
                if (file.tag === 'comp_renda') {
                  return (
                    <ContainerBotao>
                      <Botao
                        tipo=""
                        tamanho="pequeno"
                        className="btn-salvar"
                        onClick={() => {
                          window.open(file.document.signedUrl, '_blank');
                        }}
                      >
                        {file.document.signedUrl}
                      </Botao>
                      {/* <Botao
                        tipo="excluir"
                        tamanho='pequeno'
                        className="btn-salvar"
                        onClick={() => { deletDocs(file.id) }}
                      >
                        Excluir
                      </Botao> */}
                    </ContainerBotao>
                  );
                } else return null;
              })}
            </InputsContainer>
          </AccordionDetails>
        </Accordion>
      </InputsContent>
    </>
  );
};

export default PessoaFisica;
