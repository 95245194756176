import styled from 'styled-components';
import cores from '../../../styles/cores';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100vw;
  height: 100vh;
  overflow-x: auto;
`;

export const DetalhesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  width: 90%;
  height: fit-content;
  align-self: center;
  border-radius: 8px;
  box-shadow: 0px 0px 6px 2px rgba(12, 12, 14, 0.2);
  margin-bottom: 120px;
  /* padding: 0 10px; */
  h1 {
    align-self: flex-start;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 20px;
    font-size: 24px;
    font-weigth: bold;

  }
`;

export const DetalhesHeader = styled.div`
  display: flex;
  // border-bottom: 2px solid ${cores.black};
  background-color: ${cores.purple_2};
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-bottom: 50px;
  /* padding: 0 10px; */
  color: white;
  .btn {
    margin-right: 30px;
    height: 50px;
  }
  img {
    border: 0px solid black;
  }
`;

export const Imobiliaria = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 0px;

  h1 {
    align-self: center;
    font-weight: normal;
    font-size: 18px;
  }

  span{
    font-size: 24px;
    font-weight: bold;
  }
  svg {
  }
`;

export const BotoesContainer = styled.div`
  display: flex;
  border: 0px solid black;
  align-self: flex-end;
  margin-top: 40px;
  padding: 0;
  width: 100%;

  .btn-salvar {
    width: 200px;
    margin-right: 20px;
  }

  .btn-exluir {
  }
`;

export const TrocarFoto = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid lightgrey;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(-45px, 45px);
  background-color: white;
  cursor: pointer;
  svg {
    font-size: 26px;
    fill: ${cores.purple_1};
  }
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: fit-content;
  height: 100%;
  .endereco {
    width: 100%;
    font-size: 20px;
    margin: 15px 0;
    h1 {
      font-weigth: bold;
    }
  }
`;

export const DetalhesContent = styled.div`
  display: flex;
  border: 0px solid black;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  max-width: 100%;
  margin-left: 20px;
  margin-bottom: 20px;
  .item {
    display: flex;
    flex-direction: column;
    border: 0px solid black;
    width: fit-content;
    margin-top: 10px;
    margin-right: 50px;

    h2 {
      font-size: 18px;
      font-weight: bold;
    }
  }
`;

export const InputsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 0px;
  padding: 0;
  margin: 10px 10px;
  max-width: 100%;

  .input {
    width: 250px;
  }
`;

export const InputsContent = styled.div`
  display: flex;
  border: 0px solid black;
  flex-wrap: wrap;
  width: 100%;
  padding: 0;

  .ajeita {
    width: fit-content;
  }

 .observacao-input-container {
    width: 100%;
    margin-right: 40px;
  }
`;
