import React, { useState, ChangeEvent } from 'react';
import { OutlinedInput, Select, MenuItem } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Botao from '../Botao/Botao';
import Modal from '../Modal/Modal';
import { BotaoContainer, ModalContainerDelete } from './styles';

interface CancelModalProps {
  isModalOpen: boolean;
  onCancelClick: (reason: string) => void;
  onCloseModal: () => void;
  onReasonChange: (reason: string) => void;
}

const CancelIfInputModal: React.FC<CancelModalProps> = ({
  isModalOpen,
  onCancelClick,
  onCloseModal,
  onReasonChange,
}) => {
  const [isConfirmationDisabled, setIsConfirmationDisabled] = useState(true);
  const [selectedReason, setSelectedReason] = useState<string>('');

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 100,
        width: 250,
      },
    },
  };

  const handleSelectChange = (event: ChangeEvent<{ value: unknown }>) => {
    const inputValue = event.target.value as string;
    setSelectedReason(inputValue);
    setIsConfirmationDisabled(
      inputValue !== 'inadimplência' && 
      inputValue !== 'solicitação' &&
      inputValue !== 'Distrato'
      ,
    );
    onReasonChange(inputValue);
  };

  const handleModalClose = () => {
    setIsConfirmationDisabled(true);
    setSelectedReason('');
    onCloseModal();
  };

  const handleDeleteClick = () => {
    setIsConfirmationDisabled(true);
    onCancelClick(selectedReason);
  };

  return (
    <Modal isOpen={isModalOpen} width={420} onClose={handleModalClose}>
      <ModalContainerDelete>
        <h1>Você deseja cancelar mesmo?</h1>
        <div className="aviso">
          <ErrorOutlineIcon />
          <p>Esse lote será cancelado permanentemente</p>
          <p>
            Para confirmar o cancelamento selecione o motivo{' '}
            <span style={{ color: 'red', fontWeight: 'bold' }}>
              inadimplência
            </span>,{' '}
            <span style={{ color: 'red', fontWeight: 'bold' }}>
              solicitação
            </span>{' '}
            ou{' '}
            <span style={{ color: 'red', fontWeight: 'bold' }}>
              distrato
            </span>{' '}
            no campo abaixo!
          </p>
        </div>
        <Select
          labelId="motivo-select-label"
          id="motivo-select"
          variant="outlined"
          label="Motivo"
          style={{ width: 300, height: 40 }}
          value={selectedReason}
          onChange={handleSelectChange}
          input={<OutlinedInput style={{ height: 40 }} label="Motivo" />}
          MenuProps={MenuProps}
        >
          <MenuItem value="solicitação">Solicitação</MenuItem>
          <MenuItem value="inadimplência">Inadimplência</MenuItem>
          <MenuItem value="distrato">Distrato</MenuItem>
        </Select>
        <BotaoContainer>
          <Botao
            tipo="excluir"
            tamanho="medio"
            className="btn"
            disabled={isConfirmationDisabled}
            onClick={handleDeleteClick}
          >
            Confirmar
          </Botao>
          <Botao tamanho="medio" className="btn" onClick={handleModalClose}>
            Fechar
          </Botao>
        </BotaoContainer>
      </ModalContainerDelete>
    </Modal>
  );
};

export default CancelIfInputModal;
