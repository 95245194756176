/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */

import { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { InputsContainer, InputsContent } from '../EditarProposta/styles';
import FileUpload from '../../../components/FileUpload';
import Botao from '../../../components/Botao/Botao';
import { ContainerBotao } from '../PessoaFisica/styles';
import ModalDevolution from '../../../components/ModalDevolution/ModalDevolution';
import PropostasService from '../../../services/PropostasService';

const DocumentsSendBoleto = (props: any) => {
  const {
    files,
    updateUploadedFiles,
    participant_id,
    name,
  } = props;
  const [modalDevolution, setModalDevolution] = useState(false)
  const [fileDelete, setFileDelete] = useState<any>()
  const [excluir, setExcluir] = useState(false)

  const deleteDocs = (file: any) => {
    setModalDevolution(true)
    setFileDelete(file)
  }

  useEffect(() => {
    if (excluir) {
      fileDelete.deletado = true
      setExcluir(false)
      PropostasService.deleteDocumentsPhysical(fileDelete.id, fileDelete.deletado)
    }
  }, [excluir])

  return (
    <>
      <InputsContent className="acordion-1">
        <Accordion className="acordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="acordion-summaryOrange"
          >
            <Typography className="anexar-documentos">
              <AttachFileIcon />
              {name}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <InputsContainer className="upload-fotos">
              <FileUpload
                accept=".jpg,.png,.jpeg,.pdf,.docx,.doc"
                label=""
                multiple
                updateFilesCb={(e: any) => {
                  updateUploadedFiles(e, name, participant_id);
                }}
              />
              {files.files.map((file: any) => {
                if ((file.tag === 'devolution_boleto' || file.tag.includes('devolution_payment_platform')) && file.deletado === false) {
                  return (
                    <ContainerBotao>
                      <Botao
                        tipo=""
                        tamanho='pequeno'
                        className="btn-salvar"
                        onClick={() => { window.open(file.document.signedUrl, "_blank"); }}
                      >
                        {file.document.signedUrl}
                      </Botao>
                      <Botao
                        tipo="excluir"
                        tamanho='pequeno'
                        className="btn-salvar"
                        onClick={() => { deleteDocs(file) }}
                      >
                        X
                      </Botao>
                    </ContainerBotao>
                  )
                } else return null
              })}
            </InputsContainer>
          </AccordionDetails>
        </Accordion>
      </InputsContent>
      <ModalDevolution
        isModalOpen={modalDevolution}
        onCloseModal={() => {
          setModalDevolution(false)
        }}
        onUpDateClick={() => {
          setExcluir(true)
        }}
      />
    </>
  );
};

export default DocumentsSendBoleto;
