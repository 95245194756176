import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import axios from 'axios';
import { OutlinedInput, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Botao from '../../../components/Botao/Botao';
import ImobiliariasService from '../../../services/ImobiliariasService';
import {
  Content,
  DetalhesContainer,
  DetalhesContent,
  DetalhesHeader,
  Usuario,
  BotoesContainer,
  InputsContainer,
  InputsContent,
} from './styles';
import InputField from '../../../components/Inputs/InputField';
import { mascaraCNPJ, mascaraTelefone } from '../../../utils/mascaras';
import EmpreendimentosService from '../../../services/EmpreendimentosService';
import { validaCNPJ } from '../../../utils/validacoes';

const NovaImobiliaria = () => {
  const [enterprises, setEnterprises] = useState<any[]>([{}]);
  const history = useHistory();
  // eslint-disable-next-line prefer-const
  let [endereco, setEndereco] = useState({
    bairro: '',
    cep: '',
    complemento: '',
    localidade: '',
    logradouro: '',
    uf: '',
  });

  const [personName, setPersonName] = React.useState<string[]>([]);
  const [agencyName, setAgencyName] = useState('');
  const [agencyCNPJ, setAgencyCNPJ] = useState('');
  const [creciJuridico, setCreciJuridicio] = useState('');
  const [creciFisico, setCreciFisico] = useState('');
  const [agencyRazaoSocial, setAgencyRazaoSocial] = useState('');
  const [agencyEmail, setAgencyEmail] = useState('');
  const [agencyCidade, setAgencyCidade] = useState('');
  const [agencyTelefone, setAgencyTelefone] = useState('');
  const [agencyLogradouro, setAgencyLogradouro] = useState('');
  const [agencyUF, setAgencyUF] = useState({ title: '', value: '' });
  const [agencyCEP, setAgencyCEP] = useState('');
  const [agencyObservacao, setAgencyObservacao] = useState('');
  const [isCNPJValid, setIsCPNJValid] = useState<boolean>(false)
  const [emailValid, setEmailValid] = useState<boolean>(false)
  const [telefoneInvalid, setTelefoneInvalid] = useState<boolean>(false)
  const baseURL = 'https://viacep.com.br/ws/';

  function voltarTela() {
    history.goBack();
  }
  const save = async () => {
    const imob = await ImobiliariasService.createImobiliaria({
      nome: agencyName,
      telefone: Number(agencyTelefone.replace(/\D/g, '')) || 0,
      email: agencyEmail,
      cnpj: agencyCNPJ,
      razao_social: agencyRazaoSocial,
      logradouro: agencyLogradouro,
      cep: agencyCEP,
      uf: agencyUF.value,
      creci_j: creciJuridico,
      creci_f: creciFisico,
      cidade: agencyCidade,
      observacao: agencyObservacao,
    })
    const enterprises_id = enterprises
      .filter((enterprise: any) => personName.indexOf(enterprise.name) > -1)
      .map((enterprise: any) => enterprise.id);

    await ImobiliariasService.updateImobiliariaEmpreendimento({
      agency_id: imob.agencies.id,
      enterprises_id,
    });
    history.goBack();
  };

  const fetchEnterprises = useCallback(async () => {
    await EmpreendimentosService.listAllEmpreendimentos().then(response => {
      setEnterprises(response.enterprise);
    });
  }, []);

  useEffect(() => {
    fetchEnterprises();
  }, []);

  const getEndereco = async (cep: string) => {
    try {
      await axios.get(`${baseURL}${cep}/json/`).then(res => {
        const teste = res.data;
        setEndereco(teste);
      });
    } catch (error) {
      console.log('erro');
    }
  };

  useEffect(() => {
    setAgencyLogradouro(endereco.logradouro);
    setAgencyCidade(endereco.localidade);
    setAgencyUF({ title: endereco.uf, value: endereco.uf });
  }, [endereco]);

  async function autoComplete(e: any) {
    setAgencyCEP(e.target.value.toString());
    if (e.target.value.length >= 8) {
      setAgencyLogradouro('');
      setAgencyCidade('');
      setAgencyUF({ title: '', value: '' });

      await getEndereco(e.target.value.toString());
    }
  }

  function setUFValue(e: any) {
    setAgencyUF({
      title: e.target.outerText,
      value: e.target.outerText.toLowerCase(),
    });
  }

  function setCNPJValue(e: any) {
    const maskedCPF = mascaraCNPJ(e.target.value);
    setAgencyCNPJ(maskedCPF);
  }

  const ufOptions = [
    { title: 'RO', value: 'RO' },
    { title: 'AC', value: 'AC' },
    { title: 'AM', value: 'AM' },
    { title: 'RR', value: 'RR' },
    { title: 'PA', value: 'PA' },
    { title: 'AP', value: 'AP' },
    { title: 'TO', value: 'TO' },
    { title: 'MA', value: 'MA' },
    { title: 'PI', value: 'PI' },
    { title: 'CE', value: 'CE' },
    { title: 'RN', value: 'RN' },
    { title: 'PB', value: 'PB' },
    { title: 'PE', value: 'PE' },
    { title: 'AL', value: 'AL' },
    { title: 'SE', value: 'SE' },
    { title: 'BA', value: 'BA' },
    { title: 'MG', value: 'MG' },
    { title: 'ES', value: 'ES' },
    { title: 'RJ', value: 'RJ' },
    { title: 'SP', value: 'SP' },
    { title: 'PR', value: 'PR' },
    { title: 'SC', value: 'SC' },
    { title: 'RS', value: 'RS' },
    { title: 'MS', value: 'MS' },
    { title: 'MT', value: 'MT' },
    { title: 'GO', value: 'GO' },
    { title: 'DF', value: 'DF' },
  ];

  const [errorValidation, setErrorValidation] = useState({
    nome: { error: false, message: '' },
    cnpj: { error: false, message: '' },
    razao_social: { error: false, message: '' },
    email: { error: false, message: '' },
    telefone: { error: false, message: '' },
    creciFisico: { error: false, message: '' },
    creciJuridico: { error: false, message: '' },
    cep: { error: false, message: '' },
    logradouro: { error: false, message: '' },
    cidade: { error: false, message: '' },
    observacao: { error: false, message: '' },
  });

  let errors: Array<boolean> = [];
  errors = [false, false, false, false, false, false, false, false];
  let errorMessage: Array<string> = [];
  errorMessage = ['', '', '', '', '', '', '', ''];

  function handleErrorChange(e: any, inputError: any) {
    if (e.target.innerText !== '') {
      setErrorValidation({
        ...errorValidation,
        [inputError]: { error: false, message: '' },
      });
    }
  }

  let error = false;

  const onSubmit = async (dataSubmit: any) => {
    console.log('dados ====>', dataSubmit)

    if (dataSubmit.nome === '') {
      error = true;
      errors[0] = true;
      errorMessage[0] = 'Campo obrigatório!';
    }

    if (dataSubmit.cnpj === '') {
      error = true;
      errors[1] = true;
      errorMessage[1] = 'Campo obrigatório';
    }

    if (!isCNPJValid) {
      error = true;
      errors[1] = true;
      errorMessage[1] = 'Campo inválido';
    }

    if (dataSubmit.razao_social === '') {
      error = true;
      errors[2] = true;
      errorMessage[2] = 'Campo obrigatório';
    }

    if (dataSubmit.email === '') {
      error = true;
      errors[3] = true;
      errorMessage[3] = 'Campo obrigatório';
    }

    if (!emailValid) {
      error = true;
      errors[3] = true;
      errorMessage[3] = 'Campo inválido';
    }

    // if(!telefoneInvalid){
    //   error = true;
    //   errors[4] = true;
    //   errorMessage[4] = 'Campo inválido';
    // }

    if (dataSubmit.telefone === '') {
      error = true;
      errors[4] = true;
      errorMessage[4] = 'Campo obrigatório';
    }

    if (dataSubmit.creciFisico === '') {
      error = true;
      errors[5] = true;
      errorMessage[5] = 'Campo obrigatório'
    }

    if (dataSubmit.creciJuridico === '') {
      error = true;
      errors[6] = true;
      errorMessage[6] = 'Campo obrigatório'
    }

    if (dataSubmit.cep === '') {
      error = true;
      errors[7] = true;
      errorMessage[7] = 'Campo obrigatório';
    }

    if (dataSubmit.logradouro === '') {
      error = true;
      errors[8] = true;
      errorMessage[8] = 'Campo obrigatório';
    }

    if (dataSubmit.cidade === '') {
      error = true;
      errors[9] = true;
      errorMessage[9] = 'Campo obrigatório';
    }

    setErrorValidation({
      ...errorValidation,
      nome: { error: errors[0], message: errors[0] ? errorMessage[0] : '' },
      cnpj: { error: errors[1], message: errors[1] ? errorMessage[1] : '' },
      razao_social: { error: errors[2], message: errors[2] ? errorMessage[2] : '' },
      email: { error: errors[3], message: errors[3] ? errorMessage[3] : '' },
      // telefone: { error: errors[4], message: errors[4] ? errorMessage[4] : '' },
      creciFisico: { error: errors[5], message: errors[5] ? errorMessage[5] : '' },
      creciJuridico: { error: errors[6], message: errors[6] ? errorMessage[6] : '' },
      cep: { error: errors[7], message: errors[7] ? errorMessage[7] : '' },
      logradouro: { error: errors[8], message: errors[8] ? errorMessage[8] : '' },
      cidade: { error: errors[9], message: errors[9] ? errorMessage[9] : '' },
    });



  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPersonName(event.target.value as string[]);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const nameValidation = (name: any) => {
    let tempName = name.target.value;
    setAgencyName(tempName)
    if (tempName.length >= 2) {
      errorValidation.nome.error = false;
      errorValidation.nome.message = '';
    }
    else {
      errorValidation.nome.error = true;
      errorValidation.nome.message = 'Campo obrigatório';
    }
  }

  const cnpjValidation = (cnpj: any) => {
    let tempCPNJ = cnpj.target.value;
    let validCPNJ = validaCNPJ(tempCPNJ)
    const cleanedValue = tempCPNJ.replace(/\D/g, '');
    const cnpjWithMask = cleanedValue.replace(
      /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
      '$1.$2.$3/$4-$5'
    );
    setAgencyCNPJ(cnpjWithMask)
    if (tempCPNJ === '') {
      errorValidation.cnpj.error = true
      setIsCPNJValid(false)
    }
    else if (validCPNJ === false) {
      setIsCPNJValid(false)
      errorValidation.cnpj.error = true;
      errorValidation.cnpj.message = 'CNPJ inválido';
    }
    else {
      setIsCPNJValid(true)
      errorValidation.cnpj.error = false;
      errorValidation.cnpj.message = '';
    }
  }

  const emailValidation = (email: any) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    let emailFormated = email.target.value
    let validation = emailRegex.test(emailFormated)
    setAgencyEmail(emailFormated);
    if (emailFormated === '') {
      errorValidation.email.error = true
      errorValidation.email.message = 'Email inválido';
      setEmailValid(false)
    }
    else if (validation === false) {
      setEmailValid(false)
      errorValidation.email.error = true
      errorValidation.email.message = 'Email inválido';
    }
    else {
      setEmailValid(true)
      errorValidation.email.error = false;
      errorValidation.email.message = '';
    }
  }

  const telefoneValidation = (cellphone: any) => {
    let tempTelefone = cellphone.target.value;
    setAgencyTelefone(tempTelefone)
    if (tempTelefone.length < 10) {
      errorValidation.telefone.error = true;
      errorValidation.telefone.message = 'Número inválido';
      setTelefoneInvalid(true)
    }
    else {
      errorValidation.telefone.error = false;
      errorValidation.telefone.message = '';
      setTelefoneInvalid(false)
    }
  }

  const creciFisicoValidation = (creci: any) => {
    let tempCreci = creci.target.value;
    setCreciFisico(tempCreci)
    if (tempCreci.length >= 3) {
      errorValidation.creciFisico.error = false;
      errorValidation.creciFisico.message = '';
    }
    else {
      errorValidation.creciFisico.error = true;
      errorValidation.creciFisico.message = 'CRECI INVÁLIDO';
    }
  }

  const creciJuridicoValidation = (creci: any) => {
    let tempCreci = creci.target.value;
    setCreciJuridicio(tempCreci)
    if (tempCreci.length >= 3) {
      errorValidation.creciJuridico.error = false;
      errorValidation.creciJuridico.message = '';
    }
    else {
      errorValidation.creciJuridico.error = true;
      errorValidation.creciJuridico.message = 'CRECI INVÁLIDO';
    }
  }

  return (
    <>
      <Content>
        <DetalhesContainer>
          <DetalhesHeader>
            <Usuario>
              <h1>Nova Imobiliária</h1>
            </Usuario>
            <Botao
              tipo="editar"
              tamanho="grande"
              className="btn"
              onClick={voltarTela}
            >
              Voltar
            </Botao>
          </DetalhesHeader>
          <h1>Dados:</h1>
          <DetalhesContent>
            <InputsContent>
              <InputsContainer>
                <InputField
                  tipo="formulario"
                  className="inputs"
                  label="Nome"
                  value={agencyName}
                  onChange={(e: any) => {
                    nameValidation(e);
                    handleErrorChange(e, 'nome');
                  }}
                  error={errorValidation.nome.error}
                  helperText={errorValidation.nome.message}
                />
              </InputsContainer>
              <InputsContainer>
                <InputField
                  tipo="formulario"
                  className="inputs"
                  label="CNPJ"
                  value={agencyCNPJ}
                  width={180}
                  onChange={(e: any) => {
                    cnpjValidation(e)
                  }}
                  error={errorValidation.cnpj.error}
                  helperText={errorValidation.cnpj.message}
                />
              </InputsContainer>
              <InputsContainer>
                <InputField
                  tipo="formulario"
                  className="inputs"
                  label="Razão Social"
                  width={200}
                  value={agencyRazaoSocial}
                  onChange={(e: any) => {
                    setAgencyRazaoSocial(e.target.value);
                    handleErrorChange(e, 'razao_social');
                  }}
                  error={errorValidation.razao_social.error}
                  helperText={errorValidation.razao_social.message}
                />
              </InputsContainer>
              <InputsContainer>
                <InputField
                  tipo="formulario"
                  className="inputs"
                  label="Email"
                  value={agencyEmail}
                  onChange={(e: any) => {
                    emailValidation(e);
                    handleErrorChange(e, 'email');
                  }}
                  error={errorValidation.email.error}
                  helperText={errorValidation.email.message}
                />
              </InputsContainer>
              <InputsContainer>
                <InputField
                  tipo="formulario"
                  className="inputs"
                  label="Telefone"
                  value={mascaraTelefone(agencyTelefone)}
                  onChange={(e: any) => {
                    telefoneValidation(e);
                    handleErrorChange(e, 'telefone');
                  }}
                // error={errorValidation.telefone.error}
                // helperText={errorValidation.telefone.message}
                />
              </InputsContainer>
              <InputsContainer>
                <InputField
                  tipo="formulario"
                  className="inputs"
                  label="CRECI FÍSICO"
                  value={creciFisico}
                  onChange={(e: any) => {
                    creciFisicoValidation(e);
                    handleErrorChange(e, 'creciFisico');
                  }}
                  error={errorValidation.creciFisico.error}
                  helperText={errorValidation.creciFisico.message}
                />
              </InputsContainer>
              <InputsContainer>
                <InputField
                  tipo="formulario"
                  className="inputs"
                  label="CRECI JURÍDICO"
                  value={creciJuridico}
                  onChange={(e: any) => {
                    creciJuridicoValidation(e);
                    handleErrorChange(e, 'creciJuridico');
                  }}
                  error={errorValidation.creciJuridico.error}
                  helperText={errorValidation.creciJuridico.message}
                />
              </InputsContainer>
              <InputsContainer>
                <FormControl
                  style={{
                    width: 200,
                    marginLeft: 10,
                  }}
                >
                  <InputLabel
                    id="demo-mutiple-checkbox-label"
                    style={{ marginTop: -10, marginLeft: 14 }}
                  >
                    Empreendimentos
                  </InputLabel>
                  <Select
                    labelId="demo-mutiple-checkbox-label"
                    id="demo-mutiple-checkbox"
                    multiple
                    variant="outlined"
                    style={{ width: 200, height: 40 }}
                    value={personName}
                    onChange={handleChange}
                    input={
                      <OutlinedInput
                        style={{ height: 40 }}
                        label="Empreendimentos"
                      />
                    }
                    renderValue={selected => (selected as string[]).join(', ')}
                    MenuProps={MenuProps}
                  >
                    {enterprises.map(enterprise => (
                      <MenuItem key={enterprise.id} value={enterprise.name}>
                        <Checkbox
                          checked={personName.indexOf(enterprise.name) > -1}
                        />
                        <ListItemText primary={enterprise.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </InputsContainer>
            </InputsContent>

            <div className="endereco">
              <h2>Endereço: </h2>
            </div>

            <InputsContent>
              <InputsContainer>
                <InputField
                  tipo="formulario"
                  className="inputs"
                  label="CEP"
                  width={100}
                  onChange={(e: any) => {
                    autoComplete(e);
                    handleErrorChange(e, 'cep');
                  }}
                  value={agencyCEP}
                  error={errorValidation.cep.error}
                  helperText={errorValidation.cep.message}
                />
              </InputsContainer>
              <InputsContainer>
                <InputField
                  tipo="formulario"
                  className="inputs"
                  label="Logradouro"
                  value={agencyLogradouro}
                  onChange={(e: any) => {
                    setAgencyLogradouro(e.target.value);
                    handleErrorChange(e, 'logradouro');
                  }}
                  error={errorValidation.logradouro.error}
                  helperText={errorValidation.logradouro.message}
                />
              </InputsContainer>
              <InputsContainer>
                <InputField
                  tipo="formulario"
                  className="inputs"
                  label="Cidade"
                  value={agencyCidade}
                  onChange={(e: any) => {
                    setAgencyCidade(e.target.value);
                    handleErrorChange(e, 'cidade');
                  }}
                  error={errorValidation.cidade.error}
                  helperText={errorValidation.cidade.message}
                />
              </InputsContainer>
              <InputsContainer>
                <Autocomplete
                  id="combo-box-demo"
                  options={ufOptions}
                  getOptionLabel={option => option.title}
                  style={{ width: 120 }}
                  value={agencyUF}
                  loading
                  loadingText="carregando"
                  onChange={setUFValue}
                  disableClearable
                  renderInput={params => (
                    <TextField
                      label="UF"
                      variant="outlined"
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      size="small"
                      error={false}
                      helperText=""
                    />
                  )}
                />
              </InputsContainer>
            </InputsContent>

            <div className="endereco">
              <h2>Observações:</h2>
            </div>

            <InputsContent>
              <InputsContainer className="observacao-input-container">
                <InputField
                  tipo="formulario"
                  className="inputs"
                  label="Observações"
                  value={agencyObservacao}
                  onChange={(e: any) => {
                    setAgencyObservacao(e.target.value);
                    handleErrorChange(e, 'observacao');
                  }}
                  error={errorValidation.observacao.error}
                  helperText={errorValidation.observacao.message}
                />
              </InputsContainer>
            </InputsContent>

            <BotoesContainer>
              <Botao
                tipo="salvar"
                tamanho="grande"
                className="btn-salvar"
                onClick={() => {
                  save();
                  onSubmit({
                    nome: agencyName,
                    cnpj: agencyCNPJ,
                    razao_social: agencyRazaoSocial,
                    email: agencyEmail,
                    telefone: agencyTelefone,
                    creciFisico: creciFisico,
                    creciJuridico: creciJuridico,
                    observacao: agencyObservacao,
                  });
                }}
              >
                Salvar Imobiliária
              </Botao>
            </BotoesContainer>
          </DetalhesContent>
        </DetalhesContainer>
      </Content>
    </>
  );
};

export default NovaImobiliaria;

