import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { InputsContainer, InputsContent } from '../EditarProposta/styles';
import PreviewFile from '../../../components/PreviewFile/PreviewFile';

const DocumentDevolutionBoleto = (props: any) => {
  const { filesUrl } = props;
  return (
    <>
      <InputsContent className="acordion-1">
        <Accordion className="acordion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className="acordion-summary-devolution"
          >
            <Typography className="anexar-documentos">
              <AttachFileIcon />
              Devoluções de Boletos
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <InputsContainer className="upload-fotos">
              {filesUrl.map((el: any) => {
                if (el.deletado) {
                  return <></>;
                } else {
                  const urlSplited = el.document.signedUrl.split('.');
                  let nome = el.document.signedUrl.split('/');
                  if (el.tag.includes('devolution_payment_platform')) {
                    const numeroBoleto = el.tag[el.tag.length - 1];
                    nome = `boleto_comissao_${numeroBoleto}`;
                  } else {
                    nome = nome[nome.length - 1];
                  }

                  let isImage = false;
                  if (
                    urlSplited[urlSplited.length - 1] === 'png' ||
                    urlSplited[urlSplited.length - 1] === 'jpg' ||
                    urlSplited[urlSplited.length - 1] === 'jpeg' ||
                    urlSplited[urlSplited.length - 1] === 'svg'
                  ) {
                    isImage = true;
                  }
                  return el.tag === 'devolution_boleto' ||
                    el.tag.includes('devolution_payment_platform') ? (
                    <PreviewFile
                      url={el.document.signedUrl}
                      nome={nome}
                      isImage={isImage}
                    />
                  ) : (
                    <></>
                  );
                }
              })}
            </InputsContainer>
          </AccordionDetails>
        </Accordion>
      </InputsContent>
    </>
  );
};

export default DocumentDevolutionBoleto;
