/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useState } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import { useHistory, useLocation } from 'react-router-dom';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import axios from 'axios';
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextInput, { BaseTextFieldProps } from '@material-ui/core/TextField';
import AvatarUsuario from '../../../assets/Icons/AvatarUsuario';
import Botao from '../../../components/Botao/Botao';
import DetalhesCorretor from '../DetalhesCorretores';
import {
  Content,
  DetalhesContainer,
  DetalhesContent,
  DetalhesHeader,
  Usuario,
  BotoesContainer,
  InputsContainer,
  InputsContent,
  TrocarFoto,
  ObservacaoContent,
} from './styles';
import InputForm from '../../../components/InputForm/InputForm';
import correioAPI from '../../../services/CorreioAPI';
import { mascaraCPF } from '../../../utils/mascaras';
import SelectField from '../../../components/SelectField/SelectField';
import UserService from '../../../services/UserService';
import InputField from '../../../components/Inputs/InputField';
import ImobiliariasService from '../../../services/ImobiliariasService';
import DeleteModal from '../../../components/DeleteModal';
import Snackbars from '../../../components/Snackbars';
import AppError from '../../../errors/AppError';

const EditarCorretores = () => {
  const [voltar, setVoltar] = useState(false);

  // eslint-disable-next-line prefer-const
  let [endereco, setEndereco] = useState({
    bairro: '',
    cep: '',
    complemento: '',
    localidade: '',
    logradouro: '',
    uf: '',
  });
  const history = useHistory();
  const location = useLocation<any>();

  const [loading, setLoading] = useState(false);
  const [nomeUser, setNomeUser] = useState('');
  const [emailUser, setEmailUser] = useState('');
  const [nascimentoUser, setNascimentoUser] = useState();
  const [bairroUser, setBairro] = useState('');
  const [complementoUser, setComplemento] = useState('');
  const [cidadeUser, setCidade] = useState('');
  const [logradouroUser, setLogradouro] = useState('');
  const [UFUser, setUF] = useState({ title: '', value: '' });
  const [cepUser, setCEP] = useState('');
  const [numeroEndereco, setNumeroEndereco] = useState('');
  const [documento, setDocumento] = useState<any>('');
  const [telefone, setTelefone] = useState<any>('');
  const [avatar, setAvatar] = useState(
    'https://upload.wikimedia.org/wikipedia/commons/thumb/2/24/Missing_avatar.svg/240px-Missing_avatar.svg.png',
  );
  const [id, setId] = useState('');
  const [agencyId, setAgencyId] = useState('');
  const [allEnterprisesNames, setAllEnterprisesName] = useState<string[]>();
  const [allEnterprises, setAllEnterprises] = useState<any[]>([{}]);
  const [enterprisesNames, setEnterprisesNames] = useState<string[]>();
  const [snackbarProps, setSnackbarProps] = useState({
    message: '',
    type: 'success',
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [userCreci, setCreci] = useState('');
  const [observacaoUser, setObservacaoUser] = useState('')

  const fetchEnterprises = useCallback(async agency_id => {
    await ImobiliariasService.findImobiliaria(agency_id).then(response => {
      setAllEnterprises(response.agency.enterprises);
      setAllEnterprisesName(
        response.agency.enterprises.map((enterpriseName: any) => {
          return enterpriseName.name;
        }),
      );
    });
  }, []);

  useEffect(() => {
    const {
      id,
      name,
      document,
      email,
      birth_date,
      phone,
      agency_id,
      agency_name,
      cep,
      avatar_url,
      rua,
      numero,
      bairro,
      cidade,
      uf,
      complemento,
      enterprises,
      creci,
      observacao
    } = location.state;
    setId(id);
    setAgencyId(agency_id);
    setNomeUser(name);
    setDocumento(document);
    setEmailUser(email);
    setNascimentoUser(birth_date);
    setTelefone(phone);
    setCEP(cep || '');
    setLogradouro(rua || '');
    setNumeroEndereco(numero || '');
    setBairro(bairro || '');
    setCidade(cidade || '');
    setUF({ title: uf, value: uf } || { title: '', value: '' });
    setComplemento(complemento || '');
    setAvatar(avatar_url);
    setCreci(creci);
    setObservacaoUser(observacao || '')
    setEnterprisesNames(
      enterprises.map((enterprise: any) => {
        return enterprise.name;
      }),
    );
    fetchEnterprises(agency_id);
  }, [location]);
  const [errorValidation, setErrorValidation] = useState({
    0: { error: false, message: '' },
    1: { error: false, message: '' },
    2: { error: false, message: '' },
    3: { error: false, message: '' },
    4: { error: false, message: '' },
    5: { error: false, message: '' },
    6: { error: false, message: '' },
    7: { error: false, message: '' },
    8: { error: false, message: '' },
    9: { error: false, message: '' },
  });

  function voltarTela() {
    history.goBack();
  }

  const baseURL = 'https://viacep.com.br/ws/';

  const getEndereco = async (cep: string) => {
    try {
      await axios.get(`${baseURL}${cep}/json/`).then(res => {
        const endereco = res.data;
        setLogradouro(endereco.logradouro);
        setBairro(endereco.bairro);
        setCidade(endereco.localidade);
        setUF({ title: endereco.uf, value: endereco.uf });
      });
    } catch (err: any) {
      throw new AppError(err);
    }
  };

  async function autoComplete(e: any) {
    setCEP(e.target.value.toString());
    if (e.target.value.length >= 8) {
      await getEndereco(e.target.value.toString());
    }
  }

  function setUFValue(e: any) {
    setUF({
      title: e.target.outerText,
      value: e.target.outerText.toLowerCase(),
    });
  }

  function setDocumentoValue(e: any) {
    const maskedCPF = mascaraCPF(e.target.value);
    setDocumento(maskedCPF);
  }

  const ufOptions = [
    { title: 'RO', value: 'RO' },
    { title: 'AC', value: 'AC' },
    { title: 'AM', value: 'AM' },
    { title: 'RR', value: 'RR' },
    { title: 'PA', value: 'PA' },
    { title: 'AP', value: 'AP' },
    { title: 'TO', value: 'TO' },
    { title: 'MA', value: 'MA' },
    { title: 'PI', value: 'PI' },
    { title: 'CE', value: 'CE' },
    { title: 'RN', value: 'RN' },
    { title: 'PB', value: 'PB' },
    { title: 'PE', value: 'PE' },
    { title: 'AL', value: 'AL' },
    { title: 'SE', value: 'SE' },
    { title: 'BA', value: 'BA' },
    { title: 'MG', value: 'MG' },
    { title: 'ES', value: 'ES' },
    { title: 'RJ', value: 'RJ' },
    { title: 'SP', value: 'SP' },
    { title: 'PR', value: 'PR' },
    { title: 'SC', value: 'SC' },
    { title: 'RS', value: 'RS' },
    { title: 'MS', value: 'MS' },
    { title: 'MT', value: 'MT' },
    { title: 'GO', value: 'GO' },
    { title: 'DF', value: 'DF' },
  ];

  // const { control, handleSubmit } = useForm();
  let errors: Array<boolean> = [];
  errors = [false, false, false, false, false, false, false, false];
  let errorMessage: Array<string> = [];
  errorMessage = ['', '', '', '', '', '', '', ''];

  function handleErrorChange(e: any, inputError: number) {
    if (e.target.value !== '') {
      setErrorValidation({
        ...errorValidation,
        [inputError]: { error: false, message: '' },
      });
    }
  }

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleChange = (event: any) => {
    setEnterprisesNames(event.target.value);
  };

  const onSubmit = async (data: any) => {
    setLoading(true)

    let error = false;
    if (data.nome.length < 2) {
      errors[0] = true;
      error = true;
      errorMessage[0] = 'Campo obrigatório!';
    }
    if (data.nome.length >= 2) {
      errors[0] = false;
    }

    if (data.documento === '') {
      errors[1] = true;
      error = true;
      errorMessage[1] = 'Campo obrigatório!';
    }
    if (data.documento !== '') {
      errors[1] = false;
    }

    if (data.email === '') {
      errors[2] = true;
      error = true;
      errorMessage[2] = 'Campo obrigatório!';
    }
    if (data.email !== '') {
      errors[2] = false;
      if (data.email.length < 5) {
        errors[2] = true;
        error = true;
        errorMessage[2] = 'Email inválido!';
      } else if (data.email.length > 5) {
        errors[2] = false;
        errorMessage[2] = '';
      }
    }
    // if (data.endereco.cep === '') {
    //   errors[3] = true;
    //   error = true;
    //   errorMessage[3] = 'Campo obrigatório!';
    // }
    // if (data.endereco.cep !== '') {
    //   errors[3] = false;
    // }

    setErrorValidation({
      ...errorValidation,
      0: { error: errors[0], message: errors[0] ? errorMessage[0] : '' },
      1: { error: errors[1], message: errors[1] ? errorMessage[1] : '' },
      2: { error: errors[2], message: errors[2] ? errorMessage[2] : '' },
      3: { error: errors[3], message: errors[3] ? errorMessage[3] : '' },
      4: { error: errors[4], message: errors[4] ? errorMessage[4] : '' },
      5: { error: errors[5], message: errors[5] ? errorMessage[5] : '' },
      6: { error: errors[6], message: errors[6] ? errorMessage[6] : '' },
      7: { error: errors[7], message: errors[7] ? errorMessage[7] : '' },
      8: { error: errors[8], message: errors[8] ? errorMessage[8] : '' },
    });
    if (!error) {
      await UserService.updateUser({
        user_id: id,
        name: nomeUser,
        document: documento,
        email: emailUser,
        phone: telefone,
        // birth_date: nascimentoUser,
        agency_id: agencyId,
        creci: userCreci,
        observacao: observacaoUser || ''
      });

      if (enterprisesNames) {
        const enterprises_id = allEnterprises
          .filter(
            (enterprise: any) => enterprisesNames.indexOf(enterprise.name) > -1,
          )
          .map((enterprise: any) => enterprise.id);

        await UserService.updateUserEnterprises({
          user_id: id,
          enterprises_id,
        });
      }

      if (logradouroUser !== '' || cidadeUser !== '' || bairroUser !== '' || UFUser.value !== '' || numeroEndereco !== '' || cepUser !== '') {
        await UserService.updateUserAddress({
          user_id: id,
          uf: UFUser.value,
          cidade: cidadeUser,
          cep: cepUser,
          rua: logradouroUser,
          numero: numeroEndereco,
          complemento: complementoUser,
          bairro: bairroUser,
        });
      }
      setLoading(false);
      history.goBack();
    }
  };

  const handleSnackbarClose = (
    event?: React.SyntheticEvent,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

  async function deletarRegistro(id: any) {
    await UserService.deleteUser(id);
    setDeleteModalOpen(false);
    setSnackbarProps({
      message: 'Corretor deletado com sucesso',
      type: 'success',
    });
    setSnackbarOpen(true);
    await sleep(2500);
    history.push('/painel/corretores');
  }

  useEffect(() => {
    console.log('aq',observacaoUser)
  },[observacaoUser])

  return (
    <>
      <Content>
        <DetalhesContainer>
          <DetalhesHeader>
            <Usuario>
              <img
                src={
                  avatar ||
                  'https://upload.wikimedia.org/wikipedia/commons/thumb/2/24/Missing_avatar.svg/240px-Missing_avatar.svg.png'
                }
                style={{ width: 128, height: 128, borderRadius: '100%' }}
                alt="avatar"
              />

              <h1>{nomeUser}</h1>
            </Usuario>
            <Botao
              tipo="editar"
              tamanho="grande"
              className="btn"
              onClick={voltarTela}
            >
              Voltar
            </Botao>
          </DetalhesHeader>
          <h1>Dados:</h1>
          <DetalhesContent>
            {/* {...register('nome')} */}
            {/* <form onSubmit={handleSubmit(onSubmit)}> */}
            <InputsContent>
              <InputsContainer>
                <InputField
                  name="nome"
                  tipo="formulario"
                  width={180}
                  className="inputs"
                  label="Nome"
                  value={nomeUser}
                  onChange={(e: any) => {
                    setNomeUser(e.target.value);
                    handleErrorChange(e, 0);
                  }}
                  error={errorValidation[0].error}
                  helperText={errorValidation[0].message}
                />
              </InputsContainer>

              <InputsContainer>
                <InputField
                  tipo="formulario"
                  name="documento"
                  className="inputs"
                  label="CPF"
                  value={documento}
                  onChange={(e: any) => {
                    setDocumentoValue(e);
                    handleErrorChange(e, 1);
                  }}
                  error={errorValidation[1].error}
                  helperText={errorValidation[1].message}
                />
              </InputsContainer>
              <InputsContainer>
                <InputField
                  tipo="formulario"
                  value={telefone}
                  onChange={(e: any) => {
                    setTelefone(e.target.value);
                  }}
                  className="inputs"
                  label="Telefone"
                />
              </InputsContainer>
              <InputsContainer>
                <InputField
                  tipo="formulario"
                  name="email"
                  className="inputs"
                  label="Email"
                  value={emailUser}
                  onChange={(e: any) => {
                    setEmailUser(e.target.value);
                    handleErrorChange(e, 2);
                  }}
                  error={errorValidation[2].error}
                  helperText={errorValidation[2].message}
                />
              </InputsContainer>
              <InputsContainer>
                <InputField
                  tipo="formulario"
                  className="inputs"
                  label="CRECI"
                  value={userCreci}
                  onChange={(e: any) => {
                    setCreci(e.target.value);
                    handleErrorChange(e, 9);
                  }}
                  error={errorValidation[9].error}
                  helperText={errorValidation[9].message}
                />
              </InputsContainer>
              <InputsContainer>
                <FormControl
                  style={{
                    display: "block",
                    width: "100%"
                  }}
                >
                  <InputLabel
                    id="demo-mutiple-checkbox-label"
                    style={{ marginTop: -10, marginLeft: 14 }}
                  >
                    Empreendimentos
                  </InputLabel>
                  {!allEnterprisesNames || !enterprisesNames ? (
                    ''
                  ) : (
                    <Select
                      labelId="demo-mutiple-checkbox-label"
                      id="demo-mutiple-checkbox"
                      multiple
                      variant="outlined"
                      style={{ width: 540, height: 40 }}
                      value={enterprisesNames}
                      onChange={handleChange}
                      input={
                        <OutlinedInput
                          style={{ height: 40 }}
                          label="Empreendimentos"
                        />
                      }
                      renderValue={(selected: any) => selected.join(', ')}
                      MenuProps={MenuProps}
                    >
                      {allEnterprisesNames.map((enterprise: any) => (
                        <MenuItem key={enterprise} value={enterprise}>
                          <Checkbox
                            checked={enterprisesNames.indexOf(enterprise) > -1}
                          />
                          <ListItemText primary={enterprise} />
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </FormControl>
              </InputsContainer>
            </InputsContent>
            <div className="endereco">
              <h2>Endereço: </h2>
            </div>

            <InputsContent>
              <InputsContainer>
                <InputField
                  tipo="formulario"
                  name="cep"
                  className="inputs"
                  label="CEP"
                  width={140}
                  onChange={autoComplete}
                  value={cepUser}
                  error={errorValidation[3].error}
                  helperText={errorValidation[3].message}
                />
              </InputsContainer>
              <InputsContainer>
                <InputField
                  tipo="formulario"
                  name="logradouro"
                  className="inputs"
                  label="Logradouro"
                  value={logradouroUser}
                  onChange={(e: any) => setLogradouro(e.target.value)}
                  error={errorValidation[4].error}
                  helperText={errorValidation[4].message}
                />
              </InputsContainer>
              <InputsContainer>
                <InputField
                  tipo="formulario"
                  name="numero"
                  className="inputs"
                  label="Número"
                  type="numeric"
                  value={numeroEndereco}
                  onChange={(e: any) => setNumeroEndereco(e.target.value)}
                  width={120}
                  error={errorValidation[5].error}
                  helperText={errorValidation[5].message}
                />
              </InputsContainer>
              <InputsContainer>
                <InputField
                  tipo="formulario"
                  name="complemento"
                  className="inputs"
                  label="Complemento"
                  value={complementoUser}
                  onChange={(e: any) => setComplemento(e.target.value)}
                  width={180}
                  error={errorValidation[6].error}
                  helperText={errorValidation[6].message}
                />
              </InputsContainer>
              <InputsContainer>
                <InputField
                  tipo="formulario"
                  name="bairro"
                  className="inputs"
                  label="Bairro"
                  width={160}
                  value={bairroUser}
                  onChange={(e: any) => setBairro(e.target.value)}
                  error={errorValidation[7].error}
                  helperText={errorValidation[7].message}
                />
              </InputsContainer>
              <InputsContainer>
                <InputField
                  tipo="formulario"
                  name="cidade"
                  className="inputs"
                  label="Cidade"
                  width={180}
                  value={cidadeUser}
                  onChange={(e: any) => setCidade(e.target.value)}
                  error={errorValidation[8].error}
                  helperText={errorValidation[8].message}
                />
              </InputsContainer>
              <InputsContainer>
                <Autocomplete
                  id="combo-box-demo"
                  options={ufOptions}
                  getOptionLabel={option => option.title}
                  style={{ width: 120 }}
                  value={UFUser}
                  loading
                  loadingText="carregando"
                  onChange={setUFValue}
                  defaultValue={ufOptions[0]}
                  disableClearable
                  renderInput={params => (
                    <TextField
                      label="UF"
                      variant="outlined"
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      size="small"
                      error={false}
                      helperText=""
                    />
                  )}
                />
              </InputsContainer>
            </InputsContent>


            <div className="endereco">
              <h2>Observações: </h2>
            </div>


            <ObservacaoContent>
                <InputField
                  tipo="formulario"
                  className="inputs"
                  label="Observações"
                  value={observacaoUser}
                  width={"100%"}
                  onChange={(e: any) => {
                    setObservacaoUser(e.target.value);
                  }}
                  />
            </ObservacaoContent>



            <BotoesContainer>
              <Botao
                tipo="salvar"
                tamanho="grande"
                isLoading={loading}
                className="btn-salvar"
                onClick={() => {
                  onSubmit({
                    nome: nomeUser,
                    documento,
                    // imobiliaria: imobiliariaUser,
                    email: emailUser,
                    endereco: {
                      cep: cepUser,
                      logradouro: logradouroUser,
                      numero: numeroEndereco,
                      complemento: complementoUser,
                      bairro: bairroUser,
                      cidade: cidadeUser,
                      // uf: UFUser.value,
                    },
                    observacao: observacaoUser
                  });
                }}
              >
                Salvar Corretor
              </Botao>
              <Botao
                tipo="deletar"
                tamanho="grande"
                className="btn-excluir"
                onClick={() => setDeleteModalOpen(true)}
              >
                Excluir Corretor
              </Botao>
            </BotoesContainer>
            {/* </form> */}
          </DetalhesContent>
        </DetalhesContainer>
        <DeleteModal
          isModalOpen={deleteModalOpen}
          onCloseModal={() => setDeleteModalOpen(false)}
          onDeleteClick={() => deletarRegistro(id)}
        />
        <Snackbars
          type={snackbarProps.type}
          handleClose={handleSnackbarClose}
          open={snackbarOpen}
        >
          {snackbarProps.message}
        </Snackbars>
      </Content>
    </>
  );
};

export default EditarCorretores;
